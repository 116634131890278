import React from 'react';
import { useDispatch } from 'react-redux';

import Logo from '../../logo/Logo';
import NavigationItems from '../navigation-items/NavigationItems';
import Backdrop from '../../UI/backdrop/Backdrop';

import { clientLogout } from '../../../redux/actions/authAction';

import classes from './SideDrawer.module.css';

const SideDrawer = props => {
    const dispatch = useDispatch();
    let attachedClass = [classes.SideDrawer, classes.Close];
    if (props.open) {
        attachedClass = [classes.SideDrawer, classes.Open];
    }

    // =========== LOGING OUT CLIENT ========== //
    const logingOut = () => {
        dispatch(clientLogout());
        console.log('LOGOUT');
        props.close();
    }

    return (
        <>
            <Backdrop show={props.show} clicked={props.close} />
            <div className={attachedClass.join(' ')}>
                <div className={classes.Logo}>
                    <Logo />
                </div>
                <nav>
                    <NavigationItems onClick={props.close} onLogout={logingOut} />
                </nav>
            </div>
        </>
    )
}

export default SideDrawer;