import React from 'react';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import classes from './ScrollDownButton.module.css';

const ScrollDownButton = props => {
    return (
        <div className={classes.Button} >
            <p>Scroll</p>
            <ArrowDownwardIcon />
        </div>
    )
};

export default ScrollDownButton;
