import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

import Modal from './Modal';
// import Stock from '../../assets/images/features/Report.png';

import classes from './ImageModal.module.css';

const ImageModal = props => {
    return (
        <Modal show={props.show} close={props.close}>
            <>
                <div className={classes.Container}>
                    <div className={classes.CloseButton} onClick={props.close} >
                        <CloseIcon />
                    </div>
                    <img src={props.imgUrl} alt={"Receipt"} />
                    
                    {/* <div style={{
                        marginTop: 20,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={props.imgUrl}
                        >
                            <button style={{
                                height: 40
                            }}>
                                Download Receipt
                            </button>
                        </a>
                    </div> */}

                </div>
            </>
        </Modal>
    )
}

export default ImageModal;