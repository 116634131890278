import React, { useState, useEffect } from 'react';
import {
    useSelector,
    // useDispatch
} from 'react-redux';
import Button from '@material-ui/core/Button';

import Colors from '../constants/Colors';

import { thousandSeparator } from '../constants/Functions';

import classes from './AccountPage.module.css';

const AccountPage = (props) => {

    // const dispatch = useDispatch();

    const [mobile, setMobile] = useState(window.matchMedia("(max-width: 899px)").matches);

    const userId = useSelector(state => state.auth.userId);
    const batch = useSelector(state => state.auth.batch);
    const balance = useSelector(state => state.auth.balance);
    const affiliate = batch && batch > 0 ? true : false;
    const name = useSelector(state => state.auth.name);
    const email = useSelector(state => state.auth.email);
    const phone = useSelector(state => state.auth.phone);

    const formatedBalance = `Rp ${thousandSeparator(balance)}`;

    // console.log('BALANCE ==== ', balance);


    useEffect(() => {
        const handler = e => setMobile(e.matches);
        window.matchMedia("(max-width: 899px)").addListener(handler);
        return () => {
            window.matchMedia("(max-width: 899px)").removeListener(handler);
        }
    }, [mobile]);


    const [selectedMenu, setselectedMenu] = useState(affiliate ? 'affiliate' : 'settings');

    const affiliateLink = `https://www.bonakapvi.com/kelas-saham/${userId}`;
    const copyLink = () => {
        const affiLinkText = document.createElement('textarea');
        affiLinkText.innerText = affiliateLink;
        document.body.appendChild(affiLinkText);
        affiLinkText.select();
        document.execCommand('copy');
        affiLinkText.remove();
        navigator.clipboard.writeText(affiliateLink);
        alert('Link Successfully Copied!');
    }

    const refreshBalance = () => {

    }

    const MenuItem = (pr) => {
        return (
            <li className={classes.AccountPage_MenuItem}>
                <p
                    className={pr.active ? classes.active : null}
                    onClick={pr.onClick}
                >
                    {pr.name}
                </p>
            </li>
        )
    }

    const ListItem = (pr) => {
        return (
            <div className={classes.AccountPage_ListItem}>
                <p style={{ width: mobile ? 60 : 150 }}>{pr.title}</p>
                {pr.link
                    ?
                    <a style={{ width: mobile ? '60%' : 600, overflow: 'show', wordBreak: mobile ? 'break-all' : null }} href={pr.content} rel="noopener noreferrer" target="_blank">{pr.content}</a>
                    :
                    <p style={{ width: mobile ? 150 : 200 }}>{pr.content}</p>
                }
            </div>
        )
    }

    return (
        <div className={classes.AccountPage}>

            <div className={classes.AccountPage_Menu}>
                <ul className={classes.AccountPage_MenuItems}>
                    {affiliate ? <MenuItem name={`Affilate`} onClick={() => setselectedMenu('affiliate')} /> : null}
                    <MenuItem name={`Settings`} onClick={() => setselectedMenu('Settings')} />
                </ul>
            </div>


            {selectedMenu === 'affiliate' && affiliate
                ?

                <div className={classes.AccountPage_AffiliateBox}>
                    <ListItem title={`Affiliate Link`} content={affiliateLink} link={true} />
                    <ListItem title={`Balance`} content={formatedBalance} />
                    <div style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        marginLeft: '10vw',
                        width: '80vw'
                    }}>
                        <Button
                            variant="contained"
                            size="medium"
                            style={{ backgroundColor: Colors.royal, color: Colors.primary, width: 110, marginTop: 30, marginBottom: 10, marginRight: 10, height: 60 }}
                            onClick={copyLink}
                        >
                            Copy Link
                        </Button>
                        <Button
                            variant="contained"
                            size="medium"
                            style={{ backgroundColor: Colors.royal, color: Colors.primary, width: 110, marginTop: 30, marginBottom: 10, marginLeft: 10, height: 60 }}
                            onClick={refreshBalance}
                        >
                            Refresh Balance
                        </Button>
                    </div>
                </div>

                :

                <div className={classes.AccountPage_SettingsBox}>
                    <ListItem title={`Name`} content={name} />
                    <ListItem title={`Email`} content={email} />
                    <ListItem title={`WhatsApp`} content={phone} />
                </div>
            }


        </div>
    );
}

export default AccountPage;
