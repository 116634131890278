import React from 'react';

import bnkv from '../../assets/images/bnkv.png';

import classes from './Logo.module.css';

const Logo = (props) => {
  return (
    <div className={classes.Logo} style={{ height: props.height }}>
      <a href="https://bonakapvi.com">
        <img src={bnkv} alt="EZ App" />
      </a>
    </div>
  );
}

export default Logo;
