import React from 'react';
import {
    useSelector
} from 'react-redux';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import LockIcon from '@material-ui/icons/Lock';

import classes from './CourseItem.module.css';

const CourseItem = (props) => {
    const selectedCourse = useSelector(state => state.course.selectedCourse);

    return (
        <div
            className={classes.CourseItem}
            style={{ backgroundColor: selectedCourse._id === props.id ? '#d8f8b7' : 'whitesmoke' }}
            onClick={props.onClick}
        >
            <div className={classes.CourseItem_Number}>
                {props.indexNumber}
            </div>
            <div className={classes.CourseItem_Title}>
                {props.title}
            </div>
            {props.completed ?
                <div className={classes.CourseItem_Checklist}>
                    <CheckCircleOutlineIcon style={{ fontSize: 30, color: 'green' }} />
                </div> : null}
            {props.locked ?
                <div className={classes.CourseItem_Checklist}>
                    <LockIcon style={{ fontSize: 30, color: 'red' }} />
                </div> : null}
        </div>
    )
};

export default CourseItem;

