import { SET_COURSES, SET_SELECTED_COURSE } from "../actions/courseAction";
import { LOGOUT } from "../actions/authAction";

const initialState = {
    courses: [],
    selectedCourse: {}
};

const courseReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_COURSES: {
            // console.log('COURSES', action.courses);
            return {
                ...state,
                courses: action.courses
            }
        }

        case SET_SELECTED_COURSE: {
            // console.log('SELECTED COURSE', action.selectedCourse);
            return {
                ...state,
                selectedCourse: {
                    ...action.selectedCourse
                }
            }
        }

        case LOGOUT: {
            return {
                ...initialState
            }
        }

        default:
            return state;
    }
}

export default courseReducer;