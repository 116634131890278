import React from 'react';

import classes from './LoadingSpinner.module.css';

const LoadingSpinner = props => {
    return (

        <div className={classes.Loader}>
            Loading...
        </div>

    )
}

export default LoadingSpinner;