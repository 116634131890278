import moment from 'moment';


// ======================== REPLACING SPACE ====================== //
export const replacingSpace = (someText) => {
    return someText.toLowerCase().toString().replace(/\s+/g, '-');
}

// ======================== THOUSAND SEPARATOR ====================== //
export const thousandSeparator = (num) => {
    let numParts = num.toString().split(".");
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return numParts.join(".");
}

// ======================== GET FIRST NAME ====================== //
export const getFirstName = (fullName) => {
    return `${fullName.split(' ')[0].substring(0, 15)}`
};

// ======================== NUMBER INPUT HANDLER ====================== //
export const numberInputHandler = (func, input) => {
    func(input.toString().replace(/[^0-9]/g, ''));
}

// ======================== SORT QTY ====================== //
export const sortByQty = (a, b) => {
    const qtyA = a.qty;
    const qtyB = b.qty;
    let comparison = 0;
    if (qtyA > qtyB) {
        comparison = 1;
    } else if (qtyA < qtyB) {
        comparison = -1;
    }
    return comparison * -1;
}

// ======================== SORT SUM ====================== //
export const sortBySum = (a, b) => {
    const sumA = a.sum;
    const sumB = b.sum;
    let comparison = 0;
    if (sumA > sumB) {
        comparison = 1;
    } else if (sumA < sumB) {
        comparison = -1;
    }
    return comparison * -1;
}


// ======================== SORT DATE ====================== //
export const sortNewToOld = (a, b) => {
    const A = a.date;
    const B = b.date;
    let comparison = 0;
    if (A > B) {
        comparison = 1;
    } else if (A < B) {
        comparison = -1;
    }
    return comparison * -1;
}

// ======================== SORT NAME ====================== //
export const sortByName = (a, b) => {
    const A = a.name.toLowerCase();
    const B = b.name.toLowerCase();
    let comparison = 0;
    if (A > B) {
        comparison = 1;
    } else if (A < B) {
        comparison = -1;
    }
    return comparison * 1;
}

// ======================== SORT TITLE ====================== //
export const sortAToZ = (a, b) => {
    const A = a.title.toLowerCase();
    const B = b.title.toLowerCase();
    let comparison = 0;
    if (A > B) {
        comparison = 1;
    } else if (A < B) {
        comparison = -1;
    }
    return comparison * 1;
}

// ======================== SORT SORT PRICE UP ====================== //
export const sortByPriceUp = (a, b) => {
    const A = +a.price;
    const B = +b.price;
    let comparison = 0;
    if (A > B) {
        comparison = 1;
    } else if (A < B) {
        comparison = -1;
    }
    return comparison * -1;
}

// ======================== SORT BY PRICE DOWN ====================== //
export const sortByPriceDown = (a, b) => {
    const A = +a.price;
    const B = +b.price;
    let comparison = 0;
    if (A > B) {
        comparison = 1;
    } else if (A < B) {
        comparison = -1;
    }
    return comparison * 1;
}

// ======================== SORT PTITLE ====================== //
export const sortByPTitle = (a, b) => {
    const A = a.pTitle.toLowerCase();
    const B = b.pTitle.toLowerCase();
    let comparison = 0;
    if (A > B) {
        comparison = 1;
    } else if (A < B) {
        comparison = -1;
    }
    return comparison * 1;
}

// ======================== SORT Y ====================== //
export const sortByY = (a, b) => {
    const yA = a.y;
    const yB = b.y;
    let comparison = 0;
    if (yA > yB) {
        comparison = 1;
    } else if (yA < yB) {
        comparison = -1;
    }
    return comparison * -1;
}

// ======================== FORMAT INCOMING PRODUCTS ====================== //
export const formatProducts = (prods) => {
    return prods.map(p => {
        return {
            id: p._id.toString(),
            title: p.title,
            price: p.price,
            desc: p.desc,
            imgUrl: p.imgUrl,
            stock: p.stock ? p.stock : null
        }
    })
};


// ======================== FORMAT ORDERS SLIM ====================== //
export const ordersToJSDate = (ords) => {
    return ords.map(o => {
        return {
            id: o._id,
            totalAT: o.totalAT,
            status: o.status,
            cName: o.cust ? o.cust.name : null,
            cId: o.cust ? o.cust._id.toString() : null,
            date: new Date(moment(o.date).valueOf())
        }
    })
};

// ======================== FORMAT ONE ORDER ====================== //
export const formatOneOrder = (o) => {
    return {
        id: o._id,
        date: new Date(moment(o.date).valueOf()),
        status: o.status,
        items: o.items,
        total: o.total,
        totalAT: o.totalAT,

        pDate: new Date(moment(o.pDate).valueOf()),
        payment: o.payment ? o.payment : null,
        updBy: o.by ? o.by.name : null,

        dorsP: o.dorsP ? o.dorsP : 0,
        vV: o.vV ? o.vV : 0,

        vId: o.vouch ? o.vouch._id : null,
        vName: o.vouch ? o.vouch.title : null,
        cId: o.cust ? o.cust._id : null,
        cName: o.cust ? o.cust.name : null,

        svcA: o.svcA ? o.svcA : 0,
        svcT: o.svcT ? o.svcT : null,
        svcV: o.svcV ? o.svcV : null,
        taxA: o.taxA ? o.taxA : 0,
        taxT: o.taxT ? o.taxT : null,
        taxV: o.taxV ? o.taxV : null
    }
};

// ======================== FORMAT INCOMING EXPENSES ====================== //
export const expensestoJSDate = (exps) => {
    return exps.map(e => {
        return {
            id: e._id,
            cat: e.cat,
            amount: e.amount,
            desc: e.desc ? e.desc : '',
            updBy: e.by ? e.by.name : null,
            userId: e.by ? e.by._id : null,
            date: new Date(moment(e.date).valueOf())
        }
    })
};

// ======================== FORMAT INCOMING CUSTOMERS ====================== //
export const formatCustomers = (customers) => {
    return customers.map(cust => {
        return {
            id: cust._id.toString(),
            name: cust.name.toString(),
            phone: cust.phone ? cust.phone.toString() : '',
            email: cust.email ? cust.email.toString() : '',
            gender: cust.gender ? cust.gender.toString() : '',
            address: cust.address ? cust.address.toString() : ''
        }
    });
}

// ======================== FORMAT INCOMING SUBS ====================== //
export const formatSubs = (subs) => {
    return subs.map(sub => {
        return {
            id: sub._id.toString(),
            amount: +sub.amount,
            date: sub.date,
            subPackage: sub.package,
            ownerId: sub.ownerId,
            checked: sub.checked ? sub.checked : false,
            approved: sub.approved ? sub.approved : false,
            notes: sub.notes ? sub.notes : ""
        }
    });
}

// ======================== FORMAT INCOMING STORES ====================== //
export const formatStores = (stores) => {
    return stores.map(store => {
        return {
            _id: store._id.toString(),
            category: store.category,
            city: store.city,
            kec: store.kec,
            kel: store.kel,
            postal: store.postal,
            prov: store.prov,
            sName: store.sName,
            svcV: store.svcV ? store.svcV : 0,
            taxV: store.taxV ? store.taxV : 0
        }
    });
}

// ======================== TRANSFORM POST OBJECT TO ARRAY ====================== //
export const postObjectToArray = (allObjects, ordering) => {
    const theArray = ordering.map(objId => allObjects[objId]);
    return theArray;
}

// ======================== TRANSFORM CART OBJECT TO ARRAY ====================== //
export const cartItemsToArray = (items) => {
    const transformedCartItems = [];
    for (const key in items) {
        transformedCartItems.push({
            pId: key,
            pTitle: items[key].pTitle,
            pPrice: items[key].pPrice,
            qty: items[key].qty,
            sum: items[key].sum,
            dors: items[key].dors,
            gram: items[key].gram,
        });
    }
    return transformedCartItems.sort((a, b) => a.pTitle.toLowerCase() > b.pTitle.toLowerCase() ? 1 : -1);
}

// ======================== TRANSFORM CART TO ORDER ITEMS ====================== //
export const transformOrderItems = (items) => {
    const transformedCartItems = [];
    for (const key in items) {
        transformedCartItems.push({
            pId: key,
            pTitle: items[key].pTitle,
            pPrice: items[key].pPrice,
            qty: items[key].qty,
            sum: items[key].sum,
            dors: items[key].dors,
            gram: items[key].gram
        });
    }
    return transformedCartItems.sort((a, b) => a.pTitle.toLowerCase() > b.pTitle.toLowerCase() ? 1 : -1);
}

// ======================== GROUPING ORDERS FOR SECTION LIST ====================== //
export const groupingOrders = (data) => {
    let localeData = data.map((item) => {
        item.key = moment(item.date).format('LL')
        return item;
    });
    // SORT DATA BY DATE
    localeData.sort((a, b) => {
        return moment(b.date).unix() - moment(a.date).unix()
    })
    // PREPARING SECTION LIST
    const groupedData = localeData.reduce(
        (accumulator, currentValue, currentIndex, array, key = currentValue.key) => {
            const keyObjectPosition = accumulator.findIndex((item) => item.key.toString() === key.toString());
            if (keyObjectPosition >= 0) {
                accumulator[keyObjectPosition].data.push(currentValue)
                return accumulator
            } else {
                return accumulator.concat({ data: [currentValue], key: key })
            }
        }, [])
    return groupedData;
}


// ======================== COMBINE ORDER ITEMS TO ONE ARRAY ====================== //
export const combiningAllItemsInOrders = (ords) => {
    let allP = [];
    ords.forEach((ord) => {
        ord.items.forEach((item) => {
            allP.push(item);
        })
    });
    return allP;
}

// ======================== REDUCE PRODUCTS IN ORDERS TO PERCENTAGE ====================== //
export const reducingProductInOrdersRp = (orders) => {
    let allProducts = [];
    orders.forEach((ord) => {
        ord.items.forEach((item) => {
            allProducts.push(item);
        })
    });
    let prodSum = [];
    if (orders.length > 0) {
        allProducts.reduce((res, value) => {
            if (!res[value.pId]) {
                res[value.pId] = {
                    pId: value.pId,
                    pTitle: value.pTitle,
                    qty: 0,
                    sum: 0
                };
                prodSum.push(res[value.pId])
            }
            res[value.pId].qty += value.qty;
            res[value.pId].sum += value.sum;
            return res;
        }, {});
    }
    const totalSales = prodSum.reduce((acc, curr) => acc + curr.sum, 0);
    const totalItemSold = prodSum.reduce((acc, curr) => acc + curr.qty, 0);
    return prodSum.sort(sortBySum).map(prod => {
        return {
            id: prod.pId,
            x: prod.pTitle,
            y: prod.sum,
            z: prod.qty,
            percentRp: Math.round((prod.sum / totalSales) * 100),
            percentQty: Math.round((prod.qty / totalItemSold) * 100)
        }
    });
}


// ======================== REDUCE ORDER ITEMS TO GET SUM OF EACH PRODUCT ====================== //
export const reducingProductInOrders = (orders) => {
    let allProducts = [];
    orders.forEach((ord) => {
        ord.items.forEach((item) => {
            allProducts.push(item);
        })
    });
    let prodSum = [];
    if (orders.length > 0) {
        allProducts.reduce((res, value) => {
            if (!res[value.pId]) {
                res[value.pId] = {
                    pId: value.pId,
                    pTitle: value.pTitle,
                    qty: 0,
                    sum: 0
                };
                prodSum.push(res[value.pId])
            }
            res[value.pId].qty += value.qty;
            res[value.pId].sum += value.sum;
            return res;
        }, {});
    }
    // const totalSales = prodSum.reduce((acc, curr) => acc + curr.sum, 0)
    return prodSum;
}

// ======================== REDUCE ORDERS PER DATE FOR GRAPH ====================== //
export const reducingOrdersPerDate = (orders) => {
    let allOrders = [];
    if (orders.length > 0) {
        orders.reduce((res, value) => {
            if (!res[moment(value.date).format('DD/MM')]) {
                res[moment(value.date).format('DD/MM')] = {
                    date: moment(value.date).format('DD/MM'),
                    totalAT: 0
                };
                allOrders.push(res[moment(value.date).format('DD/MM')])
            }
            res[moment(value.date).format('DD/MM')].totalAT += value.totalAT;
            return res;
        }, {});
    }
    const separatedOrders = allOrders.map(ord => {
        return {
            x: ord.date,
            y: ord.totalAT
        }
    })
    return separatedOrders.sort(sortNewToOld);
}

// ======================== GROUPING EXPENSES FOR SECTION LIST ====================== //
export const groupingExpenses = (data) => {
    let localeData = data.map((item) => {
        item.key = moment(item.date).format('LL')
        return item;
    });
    // SORT DATA BY DATE
    localeData.sort((a, b) => {
        return moment(b.date).unix() - moment(a.date).unix()
    })
    // PREPARING SECTION LIST
    const groupedData = localeData.reduce(
        (accumulator, currentValue, currentIndex, array, key = currentValue.key) => {
            const keyObjectPosition = accumulator.findIndex((item) => item.key.toString() === key.toString());
            if (keyObjectPosition >= 0) {
                accumulator[keyObjectPosition].data.push(currentValue)
                return accumulator
            } else {
                return accumulator.concat({ data: [currentValue], key: key })
            }
        }, [])
    return groupedData;
}

// ======================== GROUPING PROFITS FOR SECTION LIST ====================== //
export const combiningOrdersAndExpenses = (orders, expenses) => {
    const mappedOrders = orders.map(ord => {
        return {
            sumDate: moment(ord.date).format('LL'),
            income: ord.totalAT,
            expense: 0,
            date: ord.date
        }
    });
    const mappedExpenses = expenses.map(exp => {
        return {
            sumDate: moment(exp.date).format('LL'),
            income: 0,
            expense: exp.amount,
            date: exp.date
        }
    })
    const combinedArray = mappedOrders.concat(mappedExpenses);
    let finishedArray = [];
    combinedArray.reduce((res, value) => {
        if (!res[value.sumDate]) {
            res[value.sumDate] = {
                sumDate: value.sumDate,
                income: 0,
                expense: 0,
                date: value.date
            };
            finishedArray.push(res[value.sumDate])
        }
        res[value.sumDate].income += value.income;
        res[value.sumDate].expense += value.expense;
        return res;
    }, {});
    const finalArray = finishedArray.map(item => {
        return {
            x: item.sumDate,
            y: item.income - item.expense,
            expense: item.expense,
            income: item.income,
            date: item.date
        }
    });
    return finalArray.sort(sortNewToOld);
}

// ======================== GROUPING TRANSACTIONS FOR SECTION LIST ====================== //
export const groupingTransactions = (data) => {
    let localeData = data.map((item) => {
        item.key = moment(item.date).format('LL')
        return item;
    });
    // SORT DATA BY DATE
    localeData.sort((a, b) => {
        return moment(b.date).unix() - moment(a.date).unix()
    })
    // PREPARING SECTION LIST
    const groupedData = localeData.reduce(
        (accumulator, currentValue, currentIndex, array, key = currentValue.key) => {
            const keyObjectPosition = accumulator.findIndex((item) => item.key.toString() === key.toString());
            if (keyObjectPosition >= 0) {
                accumulator[keyObjectPosition].data.push(currentValue)
                return accumulator
            } else {
                return accumulator.concat({ data: [currentValue], key: key })
            }
        }, [])
    return groupedData;
}

// ======================== JOIN STAFFS & STORES ARRAY ====================== //
export const getStoreNameKeys = (staffs, stores) => {
    const joined = staffs.map(e => {
        return Object.assign({}, e, stores.reduce((acc, val) => {
            if (val._id === e.stores) {
                return val
            } else {
                return acc
            }
        }, {}))
    })
    return joined;
}

// ======================== JOIN PRODUCT INVENTORY ====================== //
export const joinProductInventory = (products, inventory) => {
    const joined = products.map(e => {
        return Object.assign({}, e, inventory.reduce((acc, val) => {
            if (val._id.toString() === e.id) {
                return val
            } else {
                return acc
            }
        }, {}))
    })
    return joined;
}

// ======================== GROUPING COURSES FOR SECTION LIST ====================== //
export const groupingCourse = (data) => {
    let localeData = data.map((item) => {
        item.key = item.sectionId.name
        return item;
    });
   
    // PREPARING SECTION LIST
    const groupedData = localeData.reduce(
        (accumulator, currentValue, currentIndex, array, key = currentValue.key) => {
            const keyObjectPosition = accumulator.findIndex((item) => item.key.toString() === key.toString());
            if (keyObjectPosition >= 0) {
                accumulator[keyObjectPosition].data.push(currentValue)
                return accumulator
            } else {
                return accumulator.concat({ data: [currentValue], key: key })
            }
        }, [])
    return groupedData;
}
