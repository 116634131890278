import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import moment from 'moment';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import TimerIcon from '@material-ui/icons/Timer';

import LoadingSpinner from '../components/UI/spinner/LoadingSpinner';
import ArticleItem from '../components/articles/ArticleItem';

import { getAllArticles } from '../redux/actions/articleAction';

import classes from './ArticlesPage.module.css';


const ArticlesPage = (props) => {

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    // const [showDetails, setShowDetails] = useState('');
    const [mobile, setMobile] = useState(window.matchMedia("(max-width: 499px)").matches);

    const allArticles = useSelector(state => state.article.allArticles);

    // ============================= WINDOW WIDTH CHANGE LISTENER ======================== //
    useEffect(() => {
        const handler = e => setMobile(e.matches);
        window.matchMedia("(max-width: 499px)").addListener(handler);
        return () => {
            window.matchMedia("(max-width: 499px)").removeListener(handler);
        }
    }, [mobile]);

    // ============================= FETCH ARTICLES DATA (CALLBACK) ======================== //
    const fetchArticleData = useCallback(async () => {
        try {
            await dispatch(getAllArticles());
            await setIsLoading(false);
        } catch (err) {
            props.history.replace("/");
        }
    }, [dispatch, props.history]);

    // ============================= AUTO FETCH ARTICLES DATA ======================== //
    useEffect(() => {
        let fetchingData = true;
        if (fetchingData) {
            fetchArticleData()
        }
    }, [fetchArticleData]);


    if (isLoading) {
        return (
            <div className={classes.Centered}>
                <LoadingSpinner />
            </div>
        )
    }



    return (

        <>

            <div className={classes.ArticlesPage}>

                <div className={classes.ArticlesPage_Header}>
                    <h1 style={{ marginBottom: 0 }}>Semua Artikel</h1>
                    <p style={{ marginTop: 0, marginBottom: 20 }}>Click judul untuk membaca.</p>
                </div>

                <div className={classes.ArticlesPage_Body}>
                    {allArticles.map(art => {
                        return (
                            <ArticleItem
                                key={art.uri}
                                title={art.title}
                                headline={art.headline}
                                uri={art.uri}
                            />
                        )
                    })}
                </div>

            </div>

        </>

    );
}

export default ArticlesPage;
