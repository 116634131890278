export const importantData = {
    wab: [
        `https://chat.whatsapp.com/Dz2uEX6fXiM3ASmkMrixAo`,
        `https://chat.whatsapp.com/FO10lINX5NMGqY7XjUmntd`,
        `https://chat.whatsapp.com/CuWVloPqP6gA6CDZPncBD6`,
        `https://chat.whatsapp.com/JzgMP52tep51phD8BiKVmO`,
        `https://chat.whatsapp.com/K0n2lPmnOz1FvzIMWRSsJ5`
    ],
    telegram: `https://t.me/joinchat/PdVQRhlqhw8xhNWGqAro8A`,
    lastPromoDate: 1629824399000,
    promoClass: `60850ee7f0e9ff2694539ac1`,
    normalClass: `610fa044c12c787f35eb2a86`,
    affiliateClass: `610fa054c12c787f35eb2a87`,
    participants: [
        `Henry`,
        `Agnes`,
        `Kevin`,
        `Yuli`,
        `Roy`,
        `Robert`,
        `Ricky`,
        `I Made`,
        `Diana`,
        `I Ketut`,
        `Adityo`,
        `Anggi`,
        `Dewa`,
        `Dewi`,
        `Erwin`,
        `Arief`,
        `Michael`,
        `Debby`,
        `Nanda`,
        `Ni Putu`,
        `Alvin`,
        `Aditya`,
        `Dwi`,
        `Santi`,
        `Bagus`,
        `Michelle`,
        `Nicholas`,
        `Giovani`,
        `Panji`,
        `Anthony`,
        `Siti`,
        `Putri`,
        `Riska`,
        `William`,
        `Riki`,
        `Yogi`,
        `Arya`,
        `Angela`,
        `Rachma`,
        `Emilly`,
        `Agus`,
        `Alex`,
        `Ananda`,
        `Nanda`,
        `Putra`,
        `Dina`,
        `Ahmad`,
        `Agung`,
        `Ivan`,
        `Egi`,
        `Helena`,
        `Rahmad`,
        `Jane`,
        `Mita`,
        `Surya`,
        `Nicholas`,
        `Jennifer`,
        `John`,
        `Eka`,
        `Dian`,
        `Iwan`,
        `Oki`,
        `Joko`,
        `Muhammad`,
        `Tania`,
        `Tahir`,
        `Frans`,
        `Gabriela`,
        `Melani`,
        `Tia`,
        `Valency`,
        `Nabila`,
        `Theo`
    ]
};

