import React from 'react';

import classes from './NavigationItem.module.css';
import { Link } from 'react-router-dom';

const NavigationItem = (props) => {
    return (
        <li className={classes.NavigationItem}>
            {props.anchorTag
                ?
                <a
                    href={props.href}
                    className={props.active ? classes.active : null}
                    onClick={props.onClick}
                >
                    {props.children}
                </a>
                :
                <Link
                    to={props.to}
                    className={props.active ? classes.active : null}
                    onClick={props.onClick}
                >
                    {props.children}
                </Link>}

        </li>
    );
}

export default NavigationItem;
