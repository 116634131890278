import api from '../../constants/Api';
// import { dasarAnalisaSaham } from '../../constants/Objects';

export const AUTHENTICATE = 'AUTHENTICATE';
export const LOGOUT = 'LOGOUT';
export const SET_PAY_ON = 'SET_PAY_ON';
export const SET_PAY_OFF = 'SET_PAY_OFF';
export const SET_TAG = 'SET_TAG';

export const setPayOn = () => {
    return { type: SET_PAY_ON, isPaying: true }
}

export const setPayOff = () => {
    return { type: SET_PAY_OFF, isPaying: false }
}

export const settingTag = (refId) => {
    return { type: SET_TAG, refId: refId }
}

// =========================== AUTHENTICATE ========================== //
export const authenticateClient = (userId, token, name, ig, email, phone, lv, value, courses, badges, batch, balance) => {
    return async (dispatch, getState) => {
        dispatch({
            type: AUTHENTICATE,
            userId: userId,
            token: token,
            name: name,
            ig: ig,
            email: email,
            phone: phone,
            lv: lv,
            value: value,
            courses: courses,
            badges: badges,
            batch: batch,
            balance: balance
        })
    }
}

// =========================== COURSE REGISTRATION ========================== //
export const courseRegister = (name, phone, email, pword, lv, value) => {
    return async (dispatch, getState) => {

        const bodyData = {
            name: name,
            phone: phone,
            email: email,
            pword: pword,
            lv: lv,
            value: value
        };

        try {
            const response = await fetch(
                api.bnkv + `/auth/signup`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify(bodyData)
                }
            );

            if (!response.ok) {
                throw new Error('Tidak dapat terkoneksi dengan server.')
            };

            const resData = await response.json();
            // console.log(resData);

            dispatch(authenticateClient(
                resData.userId,
                resData.token,
                resData.name,
                resData.ig,
                resData.email,
                resData.phone,
                resData.lv,
                resData.value,
                resData.courses,
                resData.badges,
                resData.batch,
                resData.balance
            ));

            return {
                badges: resData.badges,
                token: resData.token
            }

        } catch (err) {
            throw err;
        }
    }
};


// =========================== COURSE LOGIN ========================== //
export const courseLogin = (phone, pword) => {
    return async (dispatch, getState) => {

        const bodyData = {
            phone: phone,
            pword: pword
        };

        try {
            const response = await fetch(
                api.bnkv + `/auth/login`,
                {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify(bodyData)
                }
            );

            if (!response.ok) {
                throw new Error('Tidak dapat terkoneksi dengan server.')
            };

            const resData = await response.json();
            // console.log(resData);

            dispatch(authenticateClient(
                resData.userId,
                resData.token,
                resData.name,
                resData.ig,
                resData.email,
                resData.phone,
                resData.lv,
                resData.value,
                resData.courses,
                resData.badges,
                resData.batch,
                resData.balance
            ));

            return {
                badges: resData.badges,
                token: resData.token
            }

        } catch (err) {
            throw err;
        }
    }
};

// =========================== PASSWORD RESET LINK ========================== //
export const requestPasswordResetLink = (email) => {
    return async (dispatch, getState) => {

        const bodyData = {
            email: email,
        };

        try {
            const response = await fetch(
                api.bnkv + `/auth/reset-link`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify(bodyData)
                }
            );

            if (!response.ok) {
                throw new Error('Tidak dapat terkoneksi dengan server.')
            };
            // const resData = await response.json();
            // console.log(resData);

        } catch (err) {
            throw err;
        }
    }
};


// =========================== RESET PASSWORD SUBMIT ========================== //
export const resetPasswordSubmit = (pword, userId, resetCode) => {
    return async (dispatch, getState) => {

        const bodyData = {
            pword: pword,
            userId: userId,
            resetCode: resetCode
        };

        try {
            const response = await fetch(
                api.bnkv + `/auth/reset-password`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify(bodyData)
                }
            );

            if (!response.ok) {
                throw new Error('Tidak dapat terkoneksi dengan server.')
            };

            const resData = await response.json();
            // console.log(resData);

            dispatch(authenticateClient(
                resData.userId,
                resData.token,
                resData.name,
                resData.ig,
                resData.email,
                resData.phone,
                resData.lv,
                resData.value,
                resData.courses,
                resData.badges,
                resData.batch,
                resData.balance
            ));

        } catch (err) {
            throw err;
        }
    }
};



// =========================== REFRESH TOKEN ========================== //
export const refreshToken = () => {
    return async (dispatch, getState) => {

        const token = getState().auth.token;
        const phone = getState().auth.phone;
        const email = getState().auth.email;

        const bodyData = {
            phone: phone,
            email: email
        };

        try {
            const response = await fetch(
                api.bnkv + `/auth/refresh-token`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(bodyData)
                }
            );

            if (!response.ok) {
                throw new Error('Tidak dapat terkoneksi dengan server.')
            };

            const resData = await response.json();
            // console.log('REFRESH TOKEN RESPONSE == ', resData);

            dispatch(authenticateClient(
                resData.userId,
                resData.token,
                resData.name,
                resData.ig,
                resData.email,
                resData.phone,
                resData.lv,
                resData.value,
                resData.courses,
                resData.badges,
                resData.batch,
                resData.balance
            ));

            // console.log("TOKEN REFRESHED !!!")

        } catch (err) {
            throw err;
        }
    }
};


// =========================== CLIENT LOGOUT ========================== //
export const clientLogout = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: LOGOUT
        });
    }
};



