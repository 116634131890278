import React from 'react';
import Colors from '../../../constants/Colors';
import classes from './ListItem.module.css';

const ListItem = (props) => {
    return (
        <p 
        className={classes.ol}
        style={{
            color: props.color || Colors.white,
            fontWeight: props.fontWeight || null,
            fontSize: props.fontSize || 15,
            marginLeft: props.marginLeft || '15vw',
            marginRight: props.marginRight || '15vw',
            textAlign: "justify",
            lineHeight: 1.7,
            marginBottom: 15
        }}>
            {props.src}
        </p>
    )
};

export default ListItem;