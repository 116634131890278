import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import {
  // useDispatch,
  useSelector
} from 'react-redux';
import { Link } from 'react-router-dom';

// import LoadingSpinner from '../components/UI/spinner/LoadingSpinner';
// import ThumbnailItem from '../components/UI/thumbnails/ThumbnailItem';
// import YouTubeIcon from '@material-ui/icons/YouTube';
// import DuoIcon from '@material-ui/icons/Duo';
// import SchoolIcon from '@material-ui/icons/School';
// import MenuBookIcon from '@material-ui/icons/MenuBook';

import KISImage from '../assets/images/kis.png';
import RecorderImage from '../assets/images/rec.png';
import TelegramImage from '../assets/images/tele.png';
import WAPro from '../assets/images/wa-pro.png';
import LetterImage from '../assets/images/inv-letter.png';
// import TelegramProImage from '../assets/images/tele-pro.png';

import LiveWebinarImage from '../assets/images/live-webinar.png';

// import Colors from '../constants/Colors';

// import { getAllArticles } from '../redux/actions/articleAction';

import { importantData } from '../constants/Data';

import classes from './CourseListPage.module.css';

const CourseListPage = (props) => {

  // const dispatch = useDispatch();

  const token = useSelector(state => state.auth.token);
  const badges = useSelector(state => state.auth.badges);
  const batch = useSelector(state => state.auth.batch);
  const batchIndex = batch - 1;

  const premiumMember = badges.includes('sp101');

  // const auth = useSelector(state => state.auth);
  // console.log(auth);

  // const [isLoading, setIsLoading] = useState(true);
  // const [showDetails, setShowDetails] = useState('');
  const [mobile, setMobile] = useState(window.matchMedia("(max-width: 499px)").matches);

  // const expiryDate = importantData.lastPromoDate;
  const waURL = batch > 600000 ? importantData.wab[0] : importantData.wab[batchIndex];
  // const premiumPrice = Date.now() > expiryDate ? importantData.wab1 : importantData.wab2;

  // const allArticles = useSelector(state => state.article.allArticles);

  // ============================= WINDOW WIDTH CHANGE LISTENER ======================== //
  useEffect(() => {
    const handler = e => setMobile(e.matches);
    window.matchMedia("(max-width: 499px)").addListener(handler);
    return () => {
      window.matchMedia("(max-width: 499px)").removeListener(handler);
    }
  }, [mobile]);

  // ============================= BACK TO LOGIN IF NO TOKEN (CALLBACK) ======================== //
  const backToLoginIfNoToken = useCallback(async () => {
    if (!token) {
      props.history.replace("/course/login");
    }
  }, [token, props.history]);

  //============================= AUTO FETCH ARTICLES DATA ======================== //
  useEffect(() => {
    let fetchingData = true;
    if (fetchingData) {
      backToLoginIfNoToken()
    }
  }, [backToLoginIfNoToken]);


  // if (isLoading) {
  //   return (
  //     <div className={classes.Centered}>
  //       <LoadingSpinner />
  //     </div>
  //   )
  // }

  // const goToArticleHandler = (item) => {
  //   // console.log(item.uri)
  //   props.history.push(`/link-bio/${item.uri}`);
  // }


  return (

    <>

      <div className={classes.CourseListPage}>

        <div className={classes.CourseListPage_Header}>
          <h1>Pilih Resource</h1>
          <p>Click untuk memilih</p>
        </div>

        {/* <p style={{ textAlign: 'center', marginBottom: 20 }}>
          Yuk, tonton <strong>Rekaman Webinar Saham</strong> sebelumnya. Lalu daftar <strong>Kelas Investasi Saham (Free)</strong> selanjutnya.
        </p> */}

        {/* <a rel="noopener noreferrer"
          target="_blank"
          href="https://www.youtube.com/channel/UCOecf7hSPhhJ42DqOIM-Trw"
          style={{ textDecoration: 'none' }}
        >
          <div className={classes.CourseListPage_Button}>
            <YouTubeIcon style={{ fontSize: 50, color: 'red' }} />
            <p style={{ color: 'white', fontWeight: 'bolder' }}>Rekaman Webinar</p>
          </div>
        </a> */}

        <Link style={{ textDecoration: 'none' }} to={'/course/stock'}>
          <button className={classes.CourseListPage_Button}>
            <img src={KISImage} alt="Kelas Saham" />
            <p>Pelajaran wajib <strong>Investor Saham</strong>.</p>
          </button>
        </Link>

        <a
          rel="noopener noreferrer"
          target="_blank"
          style={{ textDecoration: 'none' }}
          href={!premiumMember
            ? importantData.telegram
            : waURL}
        >
          <button className={classes.CourseListPage_Button}>
            <img src={!premiumMember ? TelegramImage : WAPro} alt="Live Webinar" />
            {!premiumMember
              ? <p>Join Grup Telegram <strong>Pemula</strong>.</p>
              : <p>Join Grup WhatsApp <strong>Premium</strong>.</p>
            }
          </button>
        </a>

        {!premiumMember ? null :
          <Link style={{ textDecoration: 'none' }} to={'/webinar'}>
            <button className={classes.CourseListPage_Button}>
              <img src={LiveWebinarImage} alt="Live Webinar" />
              <p>Live Webinar untuk <strong>Member Premium</strong>.</p>
            </button>
          </Link>}

        <Link style={{ textDecoration: 'none' }} to={'/webinar/recording'}>
          <button className={classes.CourseListPage_Button}>
            <img src={RecorderImage} alt="Webinar Recording" />
            <p>Kumpulan <strong>Rekaman Webinar</strong> yang pernah diikuti.</p>
          </button>
        </Link>

        {!premiumMember ? null :
          <Link style={{ textDecoration: 'none' }} to={'/letter'}>
            <button className={classes.CourseListPage_Button}>
              <img src={LetterImage} alt="Investment Letter" />
              <p>Kumpulan <strong>Investment Letter</strong> yang pernah dibahas.</p>
            </button>
          </Link>}




        {/* <p style={{ textAlign: 'center', marginTop: 2 }}>
          Baca juga <strong>Artikel Saham</strong> nya.
        </p> */}

        {/* <Link style={{ textDecoration: 'none' }} to={'/articles'}>
          <div className={classes.CourseListPage_Button}>
            <MenuBookIcon style={{ fontSize: 50, color: Colors.electric }} />
            <p style={{ color: 'white', fontWeight: 'bolder' }}>Artikel Saham</p>
          </div>
        </Link> */}

        <p style={{ textAlign: 'center', marginTop: 5 }}>
          Selamat Belajar :)
        </p>

        <p style={{ textAlign: 'center' }}>
          Salam Hangat,
        </p>

        <p style={{ textAlign: 'center' }}>
          <strong>R.B.</strong>
        </p>

        {/* <div className={classes.CourseListPage_Body_Thumbnails}>
          {allArticles.map(art => {
            return (
              <ThumbnailItem
                width={window.innerWidth / 2.2}
                height={window.innerWidth / 2.2}
                key={art.img}
                imgUrl={art.img}
                onSelect={() => goToArticleHandler(art)}
              />

            )
          })}
        </div> */}

      </div>

    </>

  );

}

export default CourseListPage;
