import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { Link } from 'react-router-dom';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';
// import TimerIcon from '@material-ui/icons/Timer';

// import LoadingSpinner from '../components/UI/spinner/LoadingSpinner';
import TelegramButton from '../../components/UI/buttons/TelegramButton';

import Ask from '../../assets/images/ask.png';
import Profit from '../../assets/images/profit.jpg';
import Books from '../../assets/images/books.png';

import Colors from '../../constants/Colors';

import classes from './StockCourseLandingPage.module.css';
// import { getTopArticles } from '../../redux/actions/articleAction';

import { importantData } from '../../constants/Data';

import { settingTag } from '../../redux/actions/authAction';

const StockCourseLandingPage = (props) => {

  const dispatch = useDispatch();

  const token = useSelector(state => state.auth.token);
  const badges = useSelector(state => state.auth.badges);
  // const refId = useSelector(state => state.auth.refId);
  const isPremium = badges.includes('sp101');

  // console.log('REF ID ==== ', refId);

  const [mobile, setMobile] = useState(window.matchMedia("(max-width: 499px)").matches);
  const [desktop, setdesktop] = useState(window.matchMedia("(min-width: 900px)").matches);

  let payLink;
  const now = Date.now();
  // console.log('NOW ==', now);
  if (now < 1624618800000) {
    payLink = `/course/list`
  } else {
    payLink = `/course/stock/premium/pay`
  }



  // ============================= WINDOW WIDTH CHANGE LISTENER ======================== //
  useEffect(() => {
    const handler = e => setMobile(e.matches);
    window.matchMedia("(max-width: 499px)").addListener(handler);
    return () => {
      window.matchMedia("(max-width: 499px)").removeListener(handler);
    }
  }, [mobile]);

  // ============================= WINDOW WIDTH CHANGE LISTENER ======================== //
  useEffect(() => {
    const handler = e => setdesktop(e.matches);
    window.matchMedia("(min-width: 900px)").addListener(handler);
    return () => {
      window.matchMedia("(min-width: 900px)").removeListener(handler);
    }
  }, [mobile]);

  // ============================= SETTING REF ID ======================== //
  useEffect(() => {
    const affiliateTag = props.location.pathname.split('/').pop();
    if (affiliateTag !== 'kelas-saham' && affiliateTag !== '' && affiliateTag !== 'home') {
      dispatch(settingTag(affiliateTag));
    } else {
      dispatch(settingTag(''));
    }
  }, [props.location.pathname, dispatch]);



  // ============================= BUTTONS ======================== //
  const learnSaham = () => {
    window.scrollTo(0, 0);
    if (token && isPremium) {
      // IF TOKEN && ALREADY PAID
      props.history.push('/course/stock');
    } else {
      // IF NO TOKEN or NO PAID
      props.history.push(payLink);
      // props.history.push('/course/stock/premium/pay');
    }
  }



  return (

    <>


      <div className={classes.StockCourseLandingPage}>

        <TelegramButton
          url={importantData.telegram}
        />

        <div className={classes.StockCourseLandingPage_Header}
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${Profit})`,
            backgroundSize: 'cover'
          }}>
          <h1 className={classes.StockCourseLandingPage_Header_Hello} style={{ color: Colors.gold, marginBottom: 0 }}>PELAJARI, PRAKTIKKAN, PROFIT</h1>
          <h3 style={{ color: 'white' }}>Kelas Investasi Saham @rbonakapvi</h3>
          <div style={{
            justifyContent: 'left',
            alignItems: 'center',
            display: 'flex',
            marginLeft: '10vw',
            width: '90vw'
          }}>
            <Button
              variant="contained"
              size="medium"
              style={{ backgroundColor: Colors.royal, color: Colors.primary, width: '80vw', marginTop: 30, marginBottom: 10, maxWidth: 400 }}
              onClick={learnSaham}
            >
              Daftar Sekarang
            </Button>
          </div>
        </div>

        <div className={classes.StockCourseLandingPage_Intro} style={{ paddingTop: mobile ? 20 : 30, paddingBottom: mobile ? 20 : 60, marginTop: mobile ? 0 : 20 }}>
          <p><strong>Kelas ini dibuat</strong> khusus <strong>untuk orang awam</strong> yang baru mulai berinvestasi di pasar saham <strong>dan juga praktisi (investor)</strong> yang ingin menambah ilmu analisa saham.</p>

          <p>Sebelum lanjut, kamu <strong>sudah tahu belum</strong> jawaban untuk pertanyaan-pertanyaan berikut ini:</p>

          <div
            style={{
              flexDirection: mobile ? 'column' : 'row',
              display: 'flex',
              width: '100%',
              justifyContent: 'center'
            }}>

            <div style={{ display: 'flex', width: !desktop ? '100%' : '65%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
              <ul>
                <li><strong>Apa sebenarnya saham</strong> itu?</li>
                <li>Kenapa <strong>harga saham naik dan turun?</strong></li>
                <li>Bagaimana <strong>proses pemilihan saham</strong> yang tepat?</li>
                <li>Apa saja <strong>faktor</strong> yang perlu diperhatikan dari <strong>Laporan Keuangan</strong>?</li>
                <li>Bagaimana cara <strong>menentukan Harga Wajar</strong> suatu saham?</li>
                <li>Bagaimana cara menerapkan <strong>manajemen risiko</strong> investasi?</li>
                <li>Apa yang perlu dilakukan untuk mendapatkan <strong>profit maksimal</strong> di pasar saham?</li>
              </ul>
            </div>

            {desktop || mobile ?
              <div style={{ display: 'flex', width: mobile ? '100%' : '35%', justifyContent: 'center', alignItems: 'center' }}>
                <img style={{ height: mobile ? 200 : 300, width: mobile ? 200 : 300 }} src={Ask} alt={'ask'} />
              </div> : null}

          </div>

        </div>


        <div className={classes.StockCourseLandingPage_Header}>

          <h1 className={classes.StockCourseLandingPage_Header_Hello} style={{ color: Colors.gold, marginBottom: 0 }}>BELUM TAHU JAWABANNYA?</h1>

          <p style={{ color: 'white' }}>Tenang teman-teman :) </p>
          <p style={{ color: 'white' }}><strong>Setelah menyelesaikan</strong> semua pelajaran yang ada di <strong>Kelas Investasi Saham</strong>, kamu pasti mengerti secara mendalam mengenai <strong>Strategi Investasi, Metode Analisa & Manajemen Risiko</strong> yang tepat saat berinvestasi di pasar saham.</p>
          {/* <p style={{ color: 'white' }}>Makanya, yuk click <strong>Join Sekarang</strong> untuk langsung bergabung di <strong>Kelas Investasi Saham</strong></p> */}


          <div style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex'
          }}>
            <Button
              variant="contained"
              size="medium"
              style={{ backgroundColor: Colors.royal, color: Colors.primary, width: '80vw', marginTop: 30, marginBottom: 10, maxWidth: 400 }}
              onClick={learnSaham}
            >
              Mulai Belajar
            </Button>
          </div>

        </div>

      </div>

      <div className={classes.StockCourseLandingPage_Intro} style={{ paddingTop: mobile ? 0 : 30, paddingBottom: mobile ? 20 : 60, marginTop: mobile ? 0 : 20 }}>

        <h1 className={classes.StockCourseLandingPage_Header_Hello} style={{ color: Colors.turqoise, marginBottom: 0, marginTop: 0 }}>MATERI YANG AKAN DIPELAJARI</h1>

        <p>Buat kamu yang penasaran, berikut ini adalah daftar <strong>ilmu</strong> yang akan kamu dapat dari <strong>Kelas Investasi Saham</strong>:</p>

        <div
          style={{
            flexDirection: mobile ? 'column' : 'row',
            display: 'flex',
            width: '100%',
            justifyContent: 'center'
          }}>

          <div style={{ display: 'flex', width: !desktop ? '100%' : '55%', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
            <h3>Dasar Investasi Saham</h3>
            <ul>
              <li>Ilmu Dasar Investasi</li>
              <li>Pendalaman Investasi Saham</li>
              <li>Cara Membeli / Menjual Saham</li>
              <li>Aturan Main Investasi Saham</li>
            </ul>
            <h3>Strategi & Metode Analisa Saham</h3>
            <ul>
              <li>Strategi Dollar Cost Averaging</li>
              <li>Strategi Intelligent Investing</li>
              <li>Metode Analisa Saham (Paper, Rock, Scissors)</li>
            </ul>
            <h3>Analisa Paper (Laporan Keuangan)</h3>
            <ul>
              <li>Membaca Laporan Keuangan</li>
              <li>Pengenalan Rasio Keuangan</li>
              <li>Simulasi Analisa Laporan Keuangan</li>
              <li>Laporan Keuangan Tingkat Lanjut (Advanced)</li>
              <li>Estimasi Kinerja Masa Depan</li>
            </ul>
            <h3>Analisa Rock (Kualitas Bisnis)</h3>
            <ul>
              <li>Mempelajari Business Model Perusahaan</li>
              <li>Menentukan Kekuatan & Kelemahan Perusahaan</li>
              <li>Analisa Manajemen Perusahaan</li>
              <li>Menentukan Katalis Bisnis di Masa Depan</li>
            </ul>
            <h3>Analisa Scissors (Valuasi Harga)</h3>
            <ul>
              <li>Metode Historical Market Value</li>
              <li>Metode Relative Value</li>
              <li>Metode Graham Value</li>
              <li>Metode Intrinsic Value</li>
            </ul>
            <h3>Manajemen Portofolio</h3>
            <ul>
              <li>Risiko & Imbal Hasil Investasi</li>
              <li>Mitigasi Risiko Investasi</li>
              <li>Alokasi Aset Investasi</li>
              <li>Perencanaan Portofolio</li>
              <li>Membangun Portofolio</li>
              <li>Evaluasi Kinerja Portofolio</li>
              <li>Simulasi Manajemen Portofolio</li>
            </ul>
            <h3>Money Management</h3>
            <ul>
              <li>Pondasi Keuangan Pribadi</li>
              <li>The Power of Compounding</li>
              <li>Waktu Terbaik Membeli Saham</li>
              <li>Waktu Tepat Menjual Saham</li>
              <li>Bet Sizing</li>
              <li>Hedging</li>
            </ul>
            {/* <h3>Market & Economics</h3>
            <ul>
              <li>Supply & Demand Theory</li>
              <li>Market Types</li>
              <li>Efficient Market Hypothesis</li>
              <li>Investment Hedging</li>
            </ul> */}
          </div>

          {!mobile && desktop ?
            <div style={{ display: 'flex', width: '45%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <img style={{ height: mobile ? 200 : 900, width: mobile ? 200 : 400 }} src={Books} alt={'books'} />
            </div> : null}

        </div>

      </div>

      <div className={classes.StockCourseLandingPage_Header}>

        <h1 className={classes.StockCourseLandingPage_Header_Hello} style={{ color: Colors.gold, marginBottom: 0, marginTop: 0 }}>YUK TAKE ACTION!</h1>

        <p style={{ color: 'white', marginBottom: 0, marginTop: 0 }}><strong>Join Kelas Investasi Saham sekarang!</strong></p>

        <div style={{
          justifyContent: 'left',
          alignItems: 'center',
          display: 'flex',
          marginBottom: 50,
          marginLeft: '10vw',
          width: '90vw',
          marginTop: 0
        }}>
          <Button
            variant="contained"
            size="medium"
            style={{ backgroundColor: Colors.royal, color: Colors.primary, width: '80vw', marginTop: 30, marginBottom: 30, maxWidth: 400 }}
            onClick={learnSaham}
          >
            JOIN KELAS INVESTASI SAHAM
          </Button>
        </div>

      </div>





    </>

  );
}

export default StockCourseLandingPage;
