import React from 'react';
import { Link } from 'react-router-dom';

import Colors from '../../constants/Colors';

import classes from './ArticleItem.module.css';

const ArticleItem = (props) => {
    const artUri = `/${props.uri}`;
    return (
        <Link
            style={{ color: Colors.gold, textDecoration: 'none' }}
            to={artUri}
        >
            <div className={classes.ArticleItem}>
                <h3 style={{textDecoration: 'underline', textDecorationColor: Colors.gold}}>{props.title}</h3>
                <p>{props.headline}</p>
            </div>
        </Link>
    )
}

export default ArticleItem;