import { SET_PAYMENT_URL, SET_CLASS_TO_PAY, SET_CLASS_DETAILS } from "../actions/paymentAction";
import { LOGOUT } from "../actions/authAction";
import { importantData } from '../../constants/Data';

const initialState = {
    url: ``,
    classId: importantData.normalClass,
    name: `Kelas Investasi Saham Premium`,
    fee: 499000,
    courseGroup: 'sp101',
    cat: 'stock'
};


const webinarReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_CLASS_TO_PAY: {
            // console.log(`CLASS TO PAY == `, action.classId);
            return {
                ...state,
                classId: action.classId
            }
        }

        case SET_PAYMENT_URL: {
            return {
                ...state,
                url: action.url
            }
        }

        case SET_CLASS_DETAILS: {
            return {
                ...state,
                name: action.details.name,
                fee: action.details.fee,
                courseGroup: action.details.courseGroup,
                cat: action.details.cat
            }
        }

        case LOGOUT: {
            return {
                ...state,
                url: ``,
            }
        }


        default:
            return state;
    }
}

export default webinarReducer;