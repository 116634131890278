import React from 'react';
import TelegramIcon from '@material-ui/icons/Telegram';

import classes from './TelegramButton.module.css';

const TelegramButton = props => {

    return (
        <div className={classes.Container}>
            <div className={classes.Text}>
                <p><strong>Join Grup</strong></p>
            </div>
            <div className={classes.Telegram} >
                <a
                    href={props.url}
                    rel="noopener noreferrer"
                    target="_blank">
                    <TelegramIcon style={{ color: '#fff', fontSize: 40 }} />
                </a>
            </div>
        </div>
    )
};

export default TelegramButton;
