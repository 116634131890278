import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Colors from '../../../constants/Colors';

import classes from './SubscribeButton.module.css';

const SubscribeButton = props => {

    return (
        <div className={classes.Container}>
            <div className={classes.Text}>
                <p><strong>subscribe!</strong></p>
            </div>
            <div className={classes.Icon} >
                <a
                    href={`https://www.youtube.com/channel/UCOecf7hSPhhJ42DqOIM-Trw`}
                    rel="noopener noreferrer"
                    target="_blank">
                    <ExpandMoreIcon style={{ color: Colors.turqoise, fontSize: 40 }} />
                </a>
            </div>
        </div>
    )
};

export default SubscribeButton;
