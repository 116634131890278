import { } from "../actions/adminAction";

const initialState = {
    participants: []
};

const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
}

export default adminReducer;