import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
// import { Link } from 'react-router-dom';

import LoadingSpinner from '../components/UI/spinner/LoadingSpinner';
import ScrollDownButton from '../components/UI/buttons/ScrollDownButton';

import Colors from '../constants/Colors';

import { resetPasswordSubmit } from '../redux/actions/authAction';

import classes from './PasswordResetSubmitPage.module.css';

const PasswordResetSubmitPage = (props) => {

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    // const [showDetails, setShowDetails] = useState('');
    const [mobile, setMobile] = useState(window.matchMedia("(max-width: 499px)").matches);

    // const editedClient = useSelector(state => state.admin.editedClient);

    // const [email, setEmail] = useState('');
    const [pword, setPword] = useState('');
    const [confirmPword, setConfirmPword] = useState("");
    const [userId, setuserId] = useState("");
    const [resetCode, setresetCode] = useState("");


    // ============================= WINDOW WIDTH CHANGE LISTENER ======================== //
    useEffect(() => {
        const handler = e => setMobile(e.matches);
        window.matchMedia("(max-width: 499px)").addListener(handler);
        return () => {
            window.matchMedia("(max-width: 499px)").removeListener(handler);
        }
    }, [mobile]);

    useEffect(() => {
        let urlArray = props.location.pathname.split('/');
        urlArray.pop();
        setuserId(urlArray.pop());
        setresetCode(urlArray.pop());
    }, [props.location.pathname]);


    if (isLoading) {
        return (
            <div className={classes.Centered}>
                <LoadingSpinner />
            </div>
        )
    }

    // ====================== PASSWORD INPUT HANDLER ======================= //
    const passwordInputHandler = (event) => {
        const { value } = event.target;
        setPword(value);
    }

    // ====================== CONFIRM PASSWORD INPUT HANDLER ======================= //
    const confirmPasswordInputHandler = (event) => {
        const { value } = event.target;
        setConfirmPword(value);
    }





    // // ====================== GO BACK HANDLER ======================= //
    // const goBack = () => {
    //     props.history.goBack();
    // }



    // ====================== HANDLING REGISTRATION ======================= //
    const submitHandler = async () => {

        if (
            (pword.length < 8)
        ) {
            return alert('Password minimal 8 karakter.');
        }

        if (confirmPword !== pword) {
            return alert('Konfirmasi password tidak sesuai.')
        }

        try {
            setIsLoading(true);
            await dispatch(resetPasswordSubmit(pword, userId, resetCode));
            setIsLoading(false);
            props.history.push('/course/list');
        } catch (err) {
            setIsLoading(false);
            return alert('Link Reset Password sudah expired. Harap ulangi proses Reset Password dari awal.')
        }
    }



    return (

        <>

            <div className={classes.PasswordResetSubmitPage}>

                <div className={classes.PasswordResetSubmitPage_Header}>
                    <h1>New Password</h1>
                </div>

                <div className={classes.PasswordResetSubmitPage_Form}>

                    <div className={classes.PasswordResetSubmitPage_Header}>

                        <TextField
                            label="Password (8-15 karakter)"
                            type="password"
                            style={{ width: '80%', marginBottom: 10, marginLeft: '10%' }}
                            value={pword}
                            onChange={passwordInputHandler}
                            inputProps={{ maxLength: 15 }}
                        />
                        <TextField
                            label="Konfirmasi Password"
                            type="password"
                            style={{ width: '80%', marginBottom: 10, marginLeft: '10%' }}
                            value={confirmPword}
                            onChange={confirmPasswordInputHandler}
                            inputProps={{ maxLength: 15 }}
                        />

                        <div style={{ width: '100%' }}>
                            <p style={{ marginLeft: '10%', width: '80%', color: 'black' }}>
                                <b>Catat password baru kamu</b> agar tidak lupa lagi.
                            <br />
                            </p>
                        </div>

                    </div>


                    <div style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        marginBottom: 30
                    }}>
                        <Button
                            variant="contained"
                            size="medium"
                            style={{ backgroundColor: Colors.royal, color: Colors.primary, width: '80%', marginTop: 0, marginBottom: 30 }}
                            onClick={submitHandler}
                        >
                            SUBMIT NEW PASSWORD
                        </Button>
                    </div>

                </div>

            </div>

            <ScrollDownButton />

        </>

    );

}

export default PasswordResetSubmitPage;
