import { AUTHENTICATE, LOGOUT, SET_PAY_ON, SET_PAY_OFF, SET_TAG } from "../actions/authAction";
import { COMPLETED_COURSE } from '../actions/courseAction';

const initialState = {
    userId: "",
    token: "",
    name: "",
    ig: "",
    email: "",
    phone: "",
    lv: "",
    value: true,
    badges: [],
    courses: [],
    batch: 0,
    balance: 0,
    isPaying: false,
    refId: ""
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_PAY_ON: {
            return {
                ...state,
                isPaying: action.isPaying
            }
        }

        case SET_PAY_OFF: {
            return {
                ...state,
                isPaying: action.isPaying
            }
        }

        case SET_TAG: {
            return {
                ...state,
                refId: action.refId
            }
        }

        case AUTHENTICATE: {
            return {
                ...state,
                userId: action.userId,
                token: action.token,
                name: action.name,
                ig: action.ig,
                email: action.email,
                phone: action.phone,
                lv: action.lv,
                value: action.value,
                badges: action.badges,
                courses: action.courses,
                batch: action.batch,
                balance: action.balance
            }
        }

        case LOGOUT: {
            return {
                ...initialState
            }
        }

        case COMPLETED_COURSE: {
            const courseId = action.id;
            const completedCourses = state.courses.concat(courseId);
            return {
                ...state,
                courses: completedCourses
            }
        }

        default:
            return state;
    }
}

export default authReducer;