import React, {
  useState,
  useEffect,
  // useCallback
} from 'react';
import {
  // useDispatch,
  // useSelector
} from 'react-redux';
import { Link } from 'react-router-dom';

// import LoadingSpinner from '../components/UI/spinner/LoadingSpinner';
// import ThumbnailItem from '../components/UI/thumbnails/ThumbnailItem';
import YouTubeIcon from '@material-ui/icons/YouTube';
// import DuoIcon from '@material-ui/icons/Duo';
import SchoolIcon from '@material-ui/icons/School';
// import MenuBookIcon from '@material-ui/icons/MenuBook';
import TelegramIcon from '@material-ui/icons/Telegram';
// import GotradeIcon from '../assets/images/gotrade.png';
// import OneAsetIcon from '../assets/images/oneaset.png';

import Colors from '../constants/Colors';

// import { getAllArticles } from '../redux/actions/articleAction';

import classes from './ThumbnailPage.module.css';

const ThumbnailPage = (props) => {

  // const dispatch = useDispatch();

  // const [isLoading, setIsLoading] = useState(true);
  // const [showDetails, setShowDetails] = useState('');
  const [mobile, setMobile] = useState(window.matchMedia("(max-width: 499px)").matches);

  // const allArticles = useSelector(state => state.article.allArticles);

  // ============================= WINDOW WIDTH CHANGE LISTENER ======================== //
  useEffect(() => {
    const handler = e => setMobile(e.matches);
    window.matchMedia("(max-width: 499px)").addListener(handler);
    return () => {
      window.matchMedia("(max-width: 499px)").removeListener(handler);
    }
  }, [mobile]);

  // ============================= FETCH ARTICLES DATA (CALLBACK) ======================== //
  // const fetchArticleData = useCallback(async () => {
  //   try {
  //     await dispatch(getAllArticles());
  //     await setIsLoading(false);
  //   } catch (err) {
  //     props.history.replace("/");
  //   }
  // }, [dispatch, props.history]);

  // ============================= AUTO FETCH ARTICLES DATA ======================== //
  // useEffect(() => {
  //   let fetchingData = true;
  //   if (fetchingData) {
  //     fetchArticleData()
  //   }
  // }, [fetchArticleData]);


  // if (isLoading) {
  //   return (
  //     <div className={classes.Centered}>
  //       <LoadingSpinner />
  //     </div>
  //   )
  // }

  // const goToArticleHandler = (item) => {
  //   // console.log(item.uri)
  //   props.history.push(`/link-bio/${item.uri}`);
  // }



  return (

    <>

      <div className={classes.ThumbnailPage}>

        <div className={classes.ThumbnailPage_Header}>
          <h1 style={{ marginBottom: 10 }}>Halo teman !</h1>
        </div>

        <p style={{ textAlign: 'center', marginBottom: 20 }}>
          Yuk daftar <strong>Kelas Investasi Saham</strong>, lalu Subscribe <strong>Channel YouTube</strong> nya untuk mulai belajar.
        </p>

        <Link style={{ textDecoration: 'none' }} to={'/kelas_saham'}>
          <div className={classes.ThumbnailPage_Button}>
            <SchoolIcon style={{ fontSize: 45, color: Colors.honey }} />
            <p style={{ color: 'white', fontWeight: 'bolder' }}>Kelas Investasi Saham</p>
          </div>
        </Link>

        <a rel="noopener noreferrer"
          target="_blank"
          href="https://www.youtube.com/channel/UCOecf7hSPhhJ42DqOIM-Trw"
          style={{ textDecoration: 'none' }}
        >
          <div className={classes.ThumbnailPage_Button}>
            <YouTubeIcon style={{ fontSize: 50, color: 'red' }} />
            <p style={{ color: 'white', fontWeight: 'bolder' }}>Channel YouTube</p>
          </div>
        </a>

        {/* <p style={{ textAlign: 'center', marginBottom: 20 }}>
          Atau click tombol dibawah untuk mulai <strong>Investasi Saham US</strong>.
        </p> */}

        {/* <a rel="noopener noreferrer"
          target="_blank"
          href="https://heygotrade.com/referral?code=056066"
          style={{ textDecoration: 'none' }}
        >
          <div className={classes.ThumbnailPage_Button}>
            <img src={GotradeIcon} alt="Gotrade" style={{ width: 45, height: 45 }} />
            <p style={{ color: 'white', fontWeight: 'bolder' }}>Investasi Saham US</p>
          </div>
        </a> */}

        {/* <p style={{ textAlign: 'center', marginTop: 2 }}>
          Baca juga <strong>Artikel Investasi</strong> di <strong>Komunitas OneAset</strong>.
        </p> */}

        

        <p style={{ textAlign: 'center', marginTop: 2 }}>
          Join juga <strong>Grup Telegram Belajar Saham</strong>.
        </p>

        <a
          rel="noopener noreferrer"
          target="_blank"
          style={{ textDecoration: 'none' }}
          href={'https://t.me/joinchat/PdVQRhlqhw8xhNWGqAro8A'}
        >
          <div className={classes.ThumbnailPage_Button}>
            <TelegramIcon style={{ fontSize: 50, color: Colors.secondary }} />
            <p style={{ color: 'white', fontWeight: 'bolder' }}> Join Grup Telegram</p>
          </div>
        </a>




        <p style={{ textAlign: 'center', marginTop: 5 }}>
          Selamat Belajar :)
        </p>

        <p style={{ textAlign: 'center' }}>
          Salam Hangat,
        </p>

        <p style={{ textAlign: 'center' }}>
          <strong>R.B.</strong>
        </p>

        {/* <div className={classes.ThumbnailPage_Body_Thumbnails}>
          {allArticles.map(art => {
            return (
              <ThumbnailItem
                width={window.innerWidth / 2.2}
                height={window.innerWidth / 2.2}
                key={art.img}
                imgUrl={art.img}
                onSelect={() => goToArticleHandler(art)}
              />

            )
          })}
        </div> */}

      </div>

    </>

  );

}

export default ThumbnailPage;
