import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
// import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import Button from '@material-ui/core/Button';

import CourseSection from '../components/courses/CourseSection';
// import CourseItem from '../components/courses/CourseItem';
import LoadingSpinner from '../components/UI/spinner/LoadingSpinner';
import WAButton from '../components/UI/buttons/WAButton';

import { importantData } from '../constants/Data';



import Colors from '../constants/Colors';

import { getCoursesInACat, getCourseDetails, addCompletedCourse } from '../redux/actions/courseAction';
// import { refreshToken } from '../redux/actions/authAction';

import classes from './StockCoursePage.module.css';


const StockCoursePage = (props) => {

  const dispatch = useDispatch();

  const token = useSelector(state => state.auth.token);
  const badges = useSelector(state => state.auth.badges);
  const batch = useSelector(state => state.auth.batch);
  const batchIndex = batch - 1;
  const courses = useSelector(state => state.course.courses);
  const selectedCourse = useSelector(state => state.course.selectedCourse);
  const currentCourseIndex = courses.findIndex(cr => cr.no === selectedCourse.no);
  // const completedCourses = useSelector(state => state.auth.courses);
  // const badges = useSelector(state => state.auth.badges);

  // const expiryDate = importantData.lastPromoDate;
  const waURL = batch > 600000 ? importantData.wab[0] : importantData.wab[batchIndex];

  // console.log('SELECTED COURSE === ', selectedCourse);

  // const premiumPrice = Date.now() > expiryDate ? importantData.wab1 : importantData.wab2;

  let isPremium;
  if (badges.includes('sp101')) {
    isPremium = true;
  } else {
    isPremium = false;
  }

  const [isLoading, setIsLoading] = useState(true);
  // const [showDetails, setShowDetails] = useState('');
  const [mobile, setMobile] = useState(window.matchMedia("(max-width: 499px)").matches);
  // const [selectedCourse, setSelectedCourse] = useState(courses[0]);

  // const allArticles = useSelector(state => state.article.allArticles);

  // ============================= WINDOW WIDTH CHANGE LISTENER ======================== //
  useEffect(() => {
    const handler = e => setMobile(e.matches);
    window.matchMedia("(max-width: 499px)").addListener(handler);
    return () => {
      window.matchMedia("(max-width: 499px)").removeListener(handler);
    }
  }, [mobile]);

  // ============================= FETCH COURSES DATA (CALLBACK) ======================== //
  const fetchCoursesData = useCallback(async () => {
    try {
      await dispatch(getCoursesInACat(props.location.pathname.split('/').pop()));
      await setIsLoading(false);
    } catch (err) {
      props.history.replace("/course/login");
    }
  }, [dispatch, props.history, props.location.pathname]);

  // ============================= AUTO FETCH COURSES DATA ======================== //
  useEffect(() => {
    let fetchingData = true;
    if (fetchingData) {
      fetchCoursesData();
    }
  }, [fetchCoursesData]);


  if (isLoading) {
    return (
      <div className={classes.Centered}>
        <LoadingSpinner />
      </div>
    )
  }



  // =========================== COURSE CONTROLLER =========================== //

  const goToPrevCourse = async () => {
    const prevCourseIndex = currentCourseIndex - 1;
    if (prevCourseIndex < 0) {
      return null;
    }
    const prevCourse = courses[prevCourseIndex];
    setIsLoading(true);
    await dispatch(getCourseDetails(prevCourse._id));
    setIsLoading(false);
  }

  const goToNextCourse = async () => {
    // const nextCourse = courses.find(cr => cr.no === selectedCourse.next);
    const nextCourseIndex = currentCourseIndex + 1;
    if (nextCourseIndex === courses.length) {
      return null;
    }
    const nextCourse = courses[nextCourseIndex];
    setIsLoading(true);
    // console.log('NEXT COURSE', nextCourse);
    await dispatch(getCourseDetails(nextCourse._id));
    setIsLoading(false);
  }

  const clickToSelect = async (id) => {
    // console.log("ID OF THE COURSE", id);
    setIsLoading(true);
    await dispatch(getCourseDetails(id));
    setIsLoading(false);
  }

  const completingCourse = async () => {
    await dispatch(addCompletedCourse(selectedCourse._id));
    await goToNextCourse();
    // console.log('COURSE COMPLETED');
    // console.log(completedCourses);
  }

  // ============================= BUTTONS ======================== //
  
  // let payLink;
  // const now = Date.now();
  // console.log('NOW ==', now);
  // if (now < 1624618800000) {
  //   payLink = `/course/list`
  // } else {
  //   payLink = `/course/stock/premium/pay`
  // }

  const payLink = `/course/stock/premium/pay`;

  const learnSaham = () => {
    window.scrollTo(0, 0);
    props.history.push(payLink);
  }

  // ========================== RENDER ITEMS ======================== //

  if (!token) {
    return props.history.replace('/course/login');
  }


  return (

    <>

      <div className={classes.StockCoursePage}>

        {isLoading ?
          <div className={classes.Centered}>
            <LoadingSpinner />
          </div>
          :
          <>
            {!isPremium ? null :
              <WAButton
                phone={waURL}
              />}

            <div className={classes.StockCoursePage_Header}>
              <h1>{selectedCourse ? selectedCourse.title : ""}</h1>
            </div>


            <div className={classes.StockCoursePage_Body_Player}>
              <ReactPlayer
                url={selectedCourse ? selectedCourse.vidUrl : ""}
                controls={true}
                onContextMenu={e => e.preventDefault()}
                width={mobile ? '100vw' : '80vw'}
                height={mobile ? '50vw' : '40vw'}
                onEnded={completingCourse}
                config={{
                  youtube: {
                    playerVars: {
                      modestbranding: 1,
                      rel: 0,
                      autoplay: 1
                    }
                  },
                  dailymotion: {
                    params: {
                      autoplay: true,
                      "sharing-enable": false,
                      quality: 480
                    }
                  },
                  file: {
                    attributes: {
                      controlsList: 'nodownload'
                    }
                  }
                }}
              />
            </div>


            <div className={classes.StockCoursePage_Body_Button}>

              {currentCourseIndex === 0
                ? <div></div>
                :
                <button
                  onClick={goToPrevCourse}
                >
                  Prev
                </button>}

              {(!isPremium && selectedCourse.group === 'sp101') ?
                <Button
                  variant="contained"
                  size="medium"
                  style={{ backgroundColor: Colors.royal, color: Colors.primary }}
                  onClick={learnSaham}
                >
                  Join Premium
                </Button> : null}


              {currentCourseIndex + 1 === courses.length
                ? <div></div>
                :
                <button
                  onClick={goToNextCourse}
                >
                  Next
                </button>}

            </div>


            <div className={classes.StockCoursePage_Body_Description}>
              <p style={{ textAlign: 'justify' }}>
                {selectedCourse ? selectedCourse.desc : ""}
              </p>
            </div>


            {selectedCourse.toolName ?
              <div className={classes.StockCoursePage_Body_Tools}>
                <p style={{ textAlign: 'left' }}>Attachment :</p>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={selectedCourse ? selectedCourse.toolUrl : ""}>
                  {selectedCourse ? selectedCourse.toolName : ""}
                </a>
              </div> : null}

            {selectedCourse.toolPword ?
              <div className={classes.StockCoursePage_Body_Tools}>
                <p style={{ textAlign: 'left' }}>Password :</p>
                <p style={{ fontWeight: 'normal' }} >{selectedCourse ? selectedCourse.toolPword : ""}</p>
              </div> : null}

          </>
        }

        <div className={classes.StockCoursePage_Body_CourseList}>
          <h2 style={{
            fontSize: 22,
            textAlign: 'left',
            marginLeft: 0
          }}>
            Daftar Modul
          </h2>

          {/* {courses.map((cour, index) => {
            return (
              <CourseItem
                onClick={() => clickToSelect(cour)}
                indexNumber={index + 1}
                title={cour.title}
                key={cour.no}
                completed={completedCourses.includes(cour._id)}
                locked={!badges.includes(cour.group)}
              />
            )
          })} */}

          <CourseSection
            clickHandler={clickToSelect}
          />

        </div>


      </div>

    </>

  );

}

export default StockCoursePage;
