import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import NavigationItem from './navigation-item/NavigationItem';

import classes from './NavigationItems.module.css';

import { setPayOff } from '../../../redux/actions/authAction';

const NavigationItems = (props) => {

  const dispatch = useDispatch();

  const [mobile, setMobile] = useState(window.matchMedia("(max-width: 899px)").matches);

  const goToLogin = () => {
    window.scrollTo(0, 0);
    dispatch(setPayOff());
    props.onLogout();
  }

  // ============================= WINDOW WIDTH CHANGE LISTENER ======================== //
  useEffect(() => {
    const handler = e => setMobile(e.matches);
    window.matchMedia("(max-width: 899px)").addListener(handler);
    return () => {
      window.matchMedia("(max-width: 899px)").removeListener(handler);
    }
  }, [mobile]);

  const token = useSelector(state => state.auth.token);

  return (
    <ul className={classes.NavigationItems}>
      <NavigationItem onClick={props.onClick} to={token ? '/course/list' : '/'}>Home</NavigationItem>
      {<NavigationItem onClick={props.onClick} to={'/articles'}>Articles</NavigationItem>}
      <NavigationItem onClick={props.onClick} href={'mailto:raymond@bonakapvi.com'} anchorTag >Contact Me</NavigationItem>
      {token ? <NavigationItem onClick={props.onClick} to={'/account'}>My Account</NavigationItem> : null}
      {token ? <NavigationItem onClick={goToLogin} to={'/course/login'}>Logout</NavigationItem> : null}
      {!token ? <NavigationItem onClick={goToLogin} to={'/course/login'}>Login</NavigationItem> : null}
    </ul>
  );
}

export default NavigationItems;
