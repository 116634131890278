import React from 'react';

import P from './Paragraph/Paragraph';
import Strong from './Strong/Strong';
import H1 from './Header1/Header1';
import H2 from './Header2/Header2';
import Image from './Image/Image';
import LI from './ListItem/ListItem';
import LH from './ListHeader/ListHeader';

const PostElement = (props) => {
    // console.log(props.type);
    let chosenElement;
    if (props.type === "H1") {
        chosenElement = <H1 src={props.src} />
    } else if (props.type === "H2") {
        chosenElement = <H2 src={props.src} />
    } else if (props.type === "P") {
        chosenElement = <P src={props.src} />
    } else if (props.type === "Strong") {
        chosenElement = <Strong src={props.src} />
    } else if (props.type === "Image") {
        chosenElement = <Image src={props.src} />
    } else if (props.type === "LI") {
        chosenElement = <LI src={props.src} />
    } else if (props.type === "LH") {
        chosenElement = <LH src={props.src} />
    } else {
        chosenElement = <div></div>
    };

    return (
        chosenElement
    );
};

export default PostElement;