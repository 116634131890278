import React from 'react';
import Colors from '../../../constants/Colors';

const Strong = (props) => {
    return (
        <strong>
            <article style={{
                color: props.color || Colors.white,
                fontWeight: props.fontWeight || null,
                fontSize: props.fontSize || 17,
                marginLeft: props.marginLeft || '10vw',
                marginRight: props.marginRight || '10vw',
                textAlign: "justify",
                lineHeight: 1.7,
                marginBottom: 30
            }}>
                {props.src}
            </article>
        </strong>
    )
};

export default Strong;