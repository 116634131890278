// ================= SECOND EDITION =============== //

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import ReduxThunk from 'redux-thunk';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import HomePage from './pages/HomePage';
import PostPage from './pages/PostPage';
import ArticlesPage from './pages/ArticlesPage';
import ThumbnailPage from './pages/ThumbnailPage';
import WebinarPage from './pages/WebinarPage';
import WebinarListPage from './pages/WebinarListPage';
import CourseLoginPage from './pages/CourseLoginPage';
import CourseListPage from './pages/CourseListPage';
import StockCoursePage from './pages/StockCoursePage';
import SpecialWebinarPage from './pages/SpecialWebinarPage';
import WebinarRecPage from './pages/WebinarRecPage';
import PasswordResetPage from './pages/PasswordResetPage';
import PasswordResetSubmitPage from './pages/PasswordResetSubmitPage';
import ThankYouPage from './pages/stock-course/ThankYouPage';
import StockCourseLandingPage from './pages/stock-course/StockCourseLandingPage';
import StockCoursePaymentPage from './pages/stock-course/StockCoursePaymentPage';
import AccountPage from './pages/AccountPage';
import InvestmentLetterPage from './pages/inv-letter/InvestmentILetterPage';

import authReducer from './redux/reducers/authReducer';
import articleReducer from './redux/reducers/articleReducer';
import adminReducer from './redux/reducers/adminReducer';
import webinarReducer from './redux/reducers/webinarReducer';
import courseReducer from './redux/reducers/courseReducer';
import paymentReducer from './redux/reducers/paymentReducer';

import Layout from './components/layouts/Layout';


//// ================== COMBINE ALL REDUCERS ================== ////
const rootReducer = combineReducers({
  auth: authReducer,
  article: articleReducer,
  admin: adminReducer,
  webinar: webinarReducer,
  course: courseReducer,
  payment: paymentReducer
});

//// ================= REDUX CONFIG =================== ////
const persistConfig = {
  key: 'root',
  storage: localStorage,
  whitelist: ['auth', 'payment']
};

const persistedStore = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedStore, applyMiddleware(ReduxThunk));
const persistor = persistStore(store);


//// ================= APP =================== ////

const App = () => {
  return (
    <div>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Layout>
              <Switch>
                <Route path="/letter" component={InvestmentLetterPage} />
                <Route path="/kelas-saham" component={StockCourseLandingPage} />
                <Route path="/kelas_saham" component={StockCoursePaymentPage} />
                <Route path="/payment/thanks/:classId" component={ThankYouPage} />
                <Route path="/payment/thanks" component={ThankYouPage} />
                <Route path="/account/reset-password/submit/:code/:id" component={PasswordResetSubmitPage} />
                <Route path="/account/reset-password" component={PasswordResetPage} />
                <Route path="/account" component={AccountPage} />
                <Route path="/course/stock/premium/home" component={StockCourseLandingPage} />
                <Route path="/course/stock/premium/pay" component={StockCoursePaymentPage} />
                <Route path="/course/stock" component={StockCoursePage} />
                <Route path="/course/register" component={CourseLoginPage} />
                <Route path="/course/login" component={CourseLoginPage} />
                <Route path="/course/list" component={CourseListPage} />
                <Route path="/webinar/recording" component={WebinarRecPage} />
                <Route path="/webinar-list/:id" component={WebinarListPage} />
                <Route path="/special-webinar" component={SpecialWebinarPage} />
                <Route path="/webinar" component={WebinarPage} />
                <Route path="/belajar-saham" component={ThumbnailPage} exact />
                <Route path="/link-bio/:name" component={PostPage} />
                <Route path="/home" component={HomePage} exact />
                <Route path="/articles" component={ArticlesPage} exact />
                {/* <Route path="/:name" component={PostPage} /> */}
                <Route path="/" component={HomePage} />
              </Switch>
            </Layout>
          </MuiPickersUtilsProvider>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
