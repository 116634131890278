import React from 'react';
import Colors from '../../../constants/Colors';
import classes from './Paragraph.module.css';

const Paragraph = (props) => {
    return (
        <article 
        className={classes.P}
        style={{
            color: props.color || Colors.white,
            fontWeight: props.fontWeight || null,
            fontSize: props.fontSize || 15,
            marginLeft: props.marginLeft || '10vw',
            marginRight: props.marginRight || '10vw',
            textAlign: "justify",
            lineHeight: 1.7,
            marginBottom: 30
        }}>
            {props.src}
        </article>
    )
};

export default Paragraph;