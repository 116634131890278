import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
// import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// import CourseItem from '../components/courses/CourseItem';
import LoadingSpinner from '../../components/UI/spinner/LoadingSpinner';
import WebinarSection from '../../components/webinars/WebinarSection';

// import Colors from '../constants/Colors';

// import { getCoursesInACat, getCourseDetails, addCompletedCourse } from '../redux/actions/courseAction';
import { getMacroUpdate } from '../../redux/actions/webinarAction';

import classes from './InvestmentLetterPage.module.css';


const InvestmentLetterPage = (props) => {

  const dispatch = useDispatch();

  const token = useSelector(state => state.auth.token);
  const recordings = useSelector(state => state.webinar.recordings);


  const [isLoading, setIsLoading] = useState(true);
  // const [showDetails, setShowDetails] = useState('');
  const [mobile, setMobile] = useState(window.matchMedia("(max-width: 499px)").matches);
  const [selectedRecording, setselectedRecording] = useState();

  const [selectedEpisode, setselectedEpisode] = useState(selectedRecording ? selectedRecording.episodes[0] : "");
  const currentEpisodeIndex = selectedRecording ? selectedRecording.episodes.findIndex(cr => cr.title === selectedEpisode.title) : 999;

  
  // console.log('SELECTED EPS ===', selectedEpisode);
  // console.log('SELECTED INDEX ===', currentEpisodeIndex);

  // ============================= WINDOW WIDTH CHANGE LISTENER ======================== //
  useEffect(() => {
    const handler = e => setMobile(e.matches);
    window.matchMedia("(max-width: 499px)").addListener(handler);
    return () => {
      window.matchMedia("(max-width: 499px)").removeListener(handler);
    }
  }, [mobile]);

  // ============================= BACK TO LOGIN IF NO TOKEN (CALLBACK) ======================== //
  const backToLoginIfNoToken = useCallback(async () => {
    if (!token) {
      props.history.replace("/course/login");
    }
  }, [token, props.history]);

  // ============================= FETCH RECORDINGS DATA (CALLBACK) ======================== //
  const fetchRecordingsData = useCallback(async () => {
    try {
      await dispatch(getMacroUpdate());
      await setIsLoading(false);
      if (selectedRecording) {
        setselectedEpisode(selectedRecording.episodes[0]);
      }
      // console.log("RUNNING...........");
    } catch (err) {
      alert("Koneksi ke server gagal. Mohon coba beberapa saat lagi...");
    }
  }, [dispatch, selectedRecording]);

  //============================= AUTO FETCH ARTICLES DATA ======================== //
  useEffect(() => {
    let fetchingData = true;
    if (fetchingData) {
      backToLoginIfNoToken()
    }
  }, [backToLoginIfNoToken]);

  // ============================= AUTO FETCH COURSES DATA ======================== //
  useEffect(() => {
    let fetchingData = true;
    if (fetchingData) {
      fetchRecordingsData();
    }
  }, [fetchRecordingsData]);

  // ====================== RECORDINGS INPUT HANDLER ======================= //
  const recordingsInputHandler = (event) => {
    const { value } = event.target;
    setselectedRecording(value);
  }

  const goToPrevVideo = async () => {
    const prevEpisodeIndex = currentEpisodeIndex - 1;
    setIsLoading(true);
    setselectedEpisode(selectedRecording.episodes[prevEpisodeIndex]);
    setTimeout(() => setIsLoading(false), 1000);
  }

  const goToNextVideo = async () => {
    if (currentEpisodeIndex === (selectedRecording.episodes.length - 1)) {
      return;
    }
    const nextEpisodeIndex = currentEpisodeIndex + 1;
    setIsLoading(true);
    setselectedEpisode(selectedRecording.episodes[nextEpisodeIndex]);
    setTimeout(() => setIsLoading(false), 1000);
  }

  const clickToSelect = async (clickedIndex) => {
    setIsLoading(true);
    setselectedEpisode(selectedRecording.episodes[clickedIndex]);
    setTimeout(() => setIsLoading(false), 1000);
  }

  const changeToOtherRecording = async () => {
    await setselectedRecording();
    await fetchRecordingsData();
  }




  if (isLoading) {
    return (
      <div className={classes.Centered}>
        <LoadingSpinner />
      </div>
    )
  }



  // ========================== RENDER ITEMS ======================== //

  if (!token) {
    return props.history.replace('/course/login');
  }


  return (

    <>

      <div className={classes.InvestmentLetterPage}>

        {isLoading ?

          <div className={classes.Centered}>
            <LoadingSpinner />
          </div>

          :

          <>

            <div className={classes.InvestmentLetterPage_Header}>
              <h3>{selectedRecording ? selectedEpisode.title : "Pilih Judul Investment Letter"}</h3>
              {selectedRecording ? null :
                <FormControl style={{ width: '80%', marginLeft: '10%', marginBottom: 10 }}>
                  <InputLabel></InputLabel>
                  <Select
                    value={selectedRecording}
                    onChange={recordingsInputHandler}
                  >
                    {recordings.map(r => {
                      return (
                        <MenuItem value={r}>{r.name}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>}
            </div>

            {selectedRecording ?
              <div className={classes.InvestmentLetterPage_Body_Player}>
                <ReactPlayer
                  url={selectedEpisode ? selectedEpisode.vidUrl : ""}
                  controls={true}
                  onContextMenu={e => e.preventDefault()}
                  width={mobile ? '100vw' : '80vw'}
                  height={mobile ? '50vw' : '40vw'}
                  onEnded={goToNextVideo}
                  config={{
                    youtube: {
                      playerVars: {
                        modestbranding: 1,
                        rel: 0,
                        autoplay: 1
                      }
                    },
                    dailymotion: {
                      params: {
                        autoplay: true,
                        "sharing-enable": false,
                        quality: 480
                      }
                    },
                    file: {
                      attributes: {
                        controlsList: 'nodownload'
                      }
                    }
                  }}
                />
              </div> : null}

            {selectedRecording && selectedRecording.episodes ?
              <div className={classes.InvestmentLetterPage_Body_Button}>
                {currentEpisodeIndex < 1 ? <div /> :
                  <button
                    onClick={goToPrevVideo}
                  >
                    Prev
                  </button>
                }


                <button
                  onClick={changeToOtherRecording}
                >
                  Other Letters
                </button>

                {currentEpisodeIndex === (selectedRecording.episodes.length - 1) ? <div /> :
                  <button
                    onClick={goToNextVideo}
                  >Next</button>
                }
              </div> : null}

            <div className={classes.InvestmentLetterPage_Body_Description}>
              <p style={{ textAlign: 'justify' }}>
                {selectedRecording ? selectedRecording.desc : ""}
              </p>
            </div>


            {selectedRecording && selectedRecording.attachmentName ?
              <div className={classes.InvestmentLetterPage_Body_Tools}>
                <p style={{ textAlign: 'left' }}>Attachment:</p>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={selectedRecording ? selectedRecording.attachmentUrl : ""}>
                  {selectedRecording ? selectedRecording.attachmentName : ""}
                </a>
              </div> : null}

              {selectedRecording && selectedRecording.attachmentPword ?
              <div className={classes.InvestmentLetterPage_Body_Tools}>
                <p style={{ textAlign: 'left' }}>Password :</p>
                <p style={{ fontWeight: 'normal' }} >{selectedRecording ? selectedRecording.attachmentPword : ""}</p>
              </div> : null}

            {selectedRecording ?
              <WebinarSection
                clickHandler={clickToSelect}
                episodes={selectedRecording ? selectedRecording.episodes : []}
              />
              : null}

          </>
        }


      </div>

    </>

  );

}

export default InvestmentLetterPage;
