import React from 'react';
// import Colors from '../../../constants/Colors';

import classes from './Image.module.css';

const Image = (props) => {
    return (
        <img
            src={props.src}
            alt={props.alt || "Image"}
            className={classes.Image}
        />
    )
};

export default Image;