import React, { useState, useEffect } from 'react';
import {
    useDispatch,
    // useSelector 
} from 'react-redux';
import TextField from '@material-ui/core/TextField';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import LoadingSpinner from '../components/UI/spinner/LoadingSpinner';
// import ScrollDownButton from '../components/UI/buttons/ScrollDownButton';

import Colors from '../constants/Colors';

import { requestPasswordResetLink } from '../redux/actions/authAction';

import classes from './PasswordResetPage.module.css';

const PasswordResetPage = (props) => {

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    // const [showDetails, setShowDetails] = useState('');
    const [mobile, setMobile] = useState(window.matchMedia("(max-width: 499px)").matches);

    const [email, setEmail] = useState('');
    const [pageMode, setpageMode] = useState('reset');


    // ============================= WINDOW WIDTH CHANGE LISTENER ======================== //
    useEffect(() => {
        const handler = e => setMobile(e.matches);
        window.matchMedia("(max-width: 499px)").addListener(handler);
        return () => {
            window.matchMedia("(max-width: 499px)").removeListener(handler);
        }
    }, [mobile]);

    if (isLoading) {
        return (
            <div className={classes.Centered}>
                <LoadingSpinner />
            </div>
        )
    }




    // ====================== EMAIL INPUT HANDLER ======================= //
    const emailInputHandler = (event) => {
        const { value } = event.target;
        setEmail(value);
    }

    // ====================== GO BACK HANDLER ======================= //
    const goBack = () => {
        props.history.goBack();
    }



    // ====================== HANDLING REGISTRATION ======================= //
    const submitHandler = async () => {
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (
            (!emailRegex.test(email.toLowerCase()))
        ) {
            return alert('Mohon isi dengan alamat email kamu.');
        }

        try {
            setIsLoading(true);
            await dispatch(requestPasswordResetLink(email));
            setIsLoading(false);
            setpageMode('completed');
        } catch (err) {
            setIsLoading(false);
            return alert('Email tidak terdaftar. Mohon gunakan email yang benar.')
        }
    }

    if (pageMode !== 'reset') {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '90vw', marginLeft: `5vw`, marginRight: '5vw', height: '100%', marginTop: '10%' }}>
                <p>Petunjuk <b>Reset Password</b> terkirim ke alamat email <strong>{email}</strong></p>
                <p>Mohon <b>cek inbox email</b> kamu untuk melanjutkan proses Reset Password.</p>
                <p>Cek juga <b>Spam / Junk</b> karena ada kemungkinan email Reset Password dikategorikan sebagai Spam oleh provider email kamu.</p>
                <Link
                    style={{ height: 40, width: 200, alignSelf: 'center', marginTop: 30, marginBottom: 40 }}
                    to={`/course/login`}
                >
                    <button style={{ height: 80, width: 200, fontSize: 16 }}>
                        Okay, saya cek email. Terima kasih !
                    </button>
                </Link>
            </div>
        )
    }


    return (

        <>

            <div className={classes.PasswordResetPage}>

                <div className={classes.PasswordResetPage_Header}>
                    <h1>Reset Password</h1>
                </div>

                <button
                    style={{ marginBottom: 30 }}
                    onClick={goBack}
                > BACK TO LOGIN</button>

                <div className={classes.PasswordResetPage_Form}>

                    <div className={classes.PasswordResetPage_Header}>

                        <TextField
                            label="Email"
                            type="email"
                            style={{ width: '80%', marginBottom: 10, marginLeft: '10%', marginTop: 30 }}
                            value={email}
                            onChange={emailInputHandler}
                            inputProps={{ maxLength: 150 }}
                        />

                        <div style={{ width: '100%' }}>
                            <p style={{ marginLeft: '10%', width: '80%', color: 'black' }}>
                                Link untuk Reset Password akan dikirimkan melalui email. <strong> Pastikan email yang kamu masukkan sudah benar</strong>.
                            <br />
                            </p>
                        </div>

                    </div>


                    <div style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        marginBottom: 30
                    }}>
                        <Button
                            variant="contained"
                            size="medium"
                            style={{ backgroundColor: Colors.royal, color: Colors.primary, width: '80%', marginTop: 0, marginBottom: 30 }}
                            onClick={submitHandler}
                        >
                            RESET PASSWORD
                        </Button>
                    </div>

                </div>

            </div>

            {/* <ScrollDownButton /> */}

        </>

    );

}

export default PasswordResetPage;
