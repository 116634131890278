const Colors = {
    header: '#000839',
    primary: '#292f33',
    secondary: "#008ecc",
    tersier: '#4ef5df',
    accent: '#00c2cb',
    turqoise: '#05828e',
    white: '#fff',
    crimson: '#880f0a',
    background: '#beebe9',
    cancel: '#707070',
    link: '#2A5DB0',
    orange: '#FF6700',
    gold: '#f9a602',
    honey: '#ffc30b',
    electric: '#7ef9ff',
    bumblebee: '#fce205',
    royal: '#fada5e',
    indigo: '#4B0082'
}

export default Colors;