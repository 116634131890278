import api from '../../constants/Api';
// import { dasarAnalisaSaham } from '../../constants/Objects';

export const GET_ALL_ARTICLES = 'GET_ALL_ARTICLES';
export const GET_TOP_ARTICLES = 'GET_TOP_ARTICLES';
export const GET_ONE_ARTICLE = 'GET_ONE_ARTICLE';
export const POST_ARTICLE = 'POST_ARTICLE';



// =========================== GET ALL ARTICLES ========================== //
export const getAllArticles = () => {
    return async (dispatch, getState) => {

        try {
            const response = await fetch(
                api.bnkv + `/article/all`,
                {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json'
                    }
                }
            );

            if (!response.ok) {
                throw new Error('Tidak dapat terkoneksi dengan server.')
            };

            const resData = await response.json();
            // console.log(resData);

            dispatch({ type: GET_ALL_ARTICLES, articles: resData.articles });

        } catch (err) {
            throw err;
        }
    }
};


// =========================== GET TOP ARTICLES ========================== //
export const getTopArticles = () => {
    return async (dispatch, getState) => {

        try {
            const response = await fetch(
                api.bnkv + `/article/top`,
                {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json'
                    }
                }
            );

            if (!response.ok) {
                throw new Error('Tidak dapat terkoneksi dengan server.')
            };

            const resData = await response.json();
            // console.log(resData);

            dispatch({ type: GET_TOP_ARTICLES, articles: resData.articles });

        } catch (err) {
            throw err;
        }
    }
};

// =========================== GET ONE ARTICLE ========================== //
export const getOneArticle = (uri) => {
    return async (dispatch, getState) => {

        try {
            const response = await fetch(
                api.bnkv + `/article/one/${uri}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json'
                    }
                }
            );

            if (!response.ok) {
                throw new Error('Tidak dapat terkoneksi dengan server.')
            };

            const resData = await response.json();
            if (!resData.article) {
                throw new Error('Artikel tidak ditemukan.')
            }
            // console.log(resData);

            dispatch({ type: GET_ONE_ARTICLE, article: resData.article });

        } catch (err) {
            throw err;
        }
    }
};

// =========================== POST ONE ARTICLE ========================== //
export const postArticle = () => {
    return async (dispatch, getState) => {

        const newArticle = 0 || "123";
        
        try {
            const response = await fetch(
                api.bnkv + "/article/post/123",
                {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        article: newArticle
                    })
                }
            );

            if (!response.ok) {
                throw new Error('Tidak dapat terkoneksi dengan server.')
            };

            // const resData = await response.json();
            // console.log(resData);

        } catch (err) {
            throw err;
        }
    }
};