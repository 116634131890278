import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import moment from 'moment';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import TimerIcon from '@material-ui/icons/Timer';

import LoadingSpinner from '../components/UI/spinner/LoadingSpinner';
import ParticipantItem from '../components/participants/ParticipantItem';

import Colors from '../constants/Colors';

import { getWebinarParticipants } from '../redux/actions/webinarAction';

import classes from './WebinarListPage.module.css';


const WebinarListPage = (props) => {

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    // const [showDetails, setShowDetails] = useState('');
    const [mobile, setMobile] = useState(window.matchMedia("(max-width: 499px)").matches);

    const [admId, setAdmId] = useState('');
    const [pword, setPword] = useState('');

    const allParticipants = useSelector(state => state.webinar.participants);

    // ============================= WINDOW WIDTH CHANGE LISTENER ======================== //
    useEffect(() => {
        const handler = e => setMobile(e.matches);
        window.matchMedia("(max-width: 499px)").addListener(handler);
        return () => {
            window.matchMedia("(max-width: 499px)").removeListener(handler);
        }
    }, [mobile]);

    // ============================= FETCH ARTICLES DATA (CALLBACK) ======================== //
    const fetchParticipants = useCallback(async () => {
        setIsLoading(true);
        const webinarId = props.location.pathname.split('/').pop();
        try {
            await dispatch(getWebinarParticipants(admId, pword, webinarId));
            await setIsLoading(false);
        } catch (err) {
            props.history.replace("/");
        }
    }, [dispatch, admId, pword, props.history, props.location.pathname]);

    // // ============================= AUTO FETCH ARTICLES DATA ======================== //
    // useEffect(() => {
    //     let fetchingData = true;
    //     if (fetchingData) {
    //         fetchArticleData()
    //     }
    // }, [fetchArticleData]);

    // ====================== WEBINAR ID INPUT HANDLER ======================= //
    const admIdInputHandler = (event) => {
        const { value } = event.target;
        setAdmId(value);
    }

    // ====================== PWORD INPUT HANDLER ======================= //
    const pwordInputHandler = (event) => {
        const { value } = event.target;
        setPword(value);
    }

    const submitHandler = async () => {
        try {
            await fetchParticipants();
        } catch (err) {
            setIsLoading(false);
        }
    };

    if (allParticipants.length < 1) {
        return (
            <div>
                <div>
                    <TextField
                        label="ID"
                        type="email"
                        style={{ width: '80vw', marginBottom: 10, marginLeft: '10vw' }}
                        value={admId}
                        onChange={admIdInputHandler}
                        inputProps={{ maxLength: 100 }}
                    />
                    <TextField
                        label="Keyword"
                        type="password"
                        style={{ width: '80vw', marginBottom: 10, marginLeft: '10vw' }}
                        value={pword}
                        onChange={pwordInputHandler}
                        inputProps={{ maxLength: 100 }}
                    />
                </div>
                <div style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}>
                    <Button
                        variant="contained"
                        size="medium"
                        style={{ backgroundColor: Colors.royal, color: Colors.primary, width: '80vw', marginTop: 0, marginBottom: 30 }}
                        onClick={submitHandler}
                    >
                        View
                </Button>
                </div>
                <div style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}>
                    <Button
                        variant="contained"
                        size="medium"
                        style={{ backgroundColor: Colors.royal, color: Colors.primary, width: '80vw', marginTop: 0, marginBottom: 30 }}
                        onClick={submitHandler}
                    >
                        Download
                </Button>
                </div>
            </div>
        )
    }


    if (isLoading) {
        return (
            <div className={classes.Centered}>
                <LoadingSpinner />
            </div>
        )
    }



    return (

        <>

            <div className={classes.WebinarListPage}>

                <div className={classes.WebinarListPage_Header}>
                    <h1 style={{ marginBottom: 0 }}>Semua Peserta</h1>
                    <p style={{ marginTop: 0, marginBottom: 20 }}>Total Peserta: {allParticipants.length} orang</p>
                </div>

                <div className={classes.WebinarListPage_Body}>
                    {allParticipants.map(part => {
                        return (
                            <ParticipantItem
                                key={part._id}
                                name={part.name}
                                phone={part.phone}
                                email={part.email}
                                ig={part.ig}
                                lv={part.lv}
                                value={part.value}
                            />
                        )
                    })}
                </div>

            </div>

        </>

    );
}

export default WebinarListPage;
