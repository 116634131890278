import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import PostElement from '../components/post/PostElement';
import LoadingSpinner from '../components/UI/spinner/LoadingSpinner';
import ScrollDownButton from '../components/UI/buttons/ScrollDownButton';

import Colors from '../constants/Colors';

import { specialWebinarReg } from '../redux/actions/webinarAction';

import classes from './SpecialWebinarPage.module.css';

const SpecialWebinarPage = (props) => {

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  // const [showDetails, setShowDetails] = useState('');
  const [mobile, setMobile] = useState(window.matchMedia("(max-width: 499px)").matches);

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [ig, setIg] = useState('');
  const [lv, setLv] = useState(0);
  const [isValue, setIsValue] = useState(true);

  const [registered, setRegistered] = useState(false);

  const availableWebinar = true;

  // ============================= WINDOW WIDTH CHANGE LISTENER ======================== //
  useEffect(() => {
    const handler = e => setMobile(e.matches);
    window.matchMedia("(max-width: 499px)").addListener(handler);
    return () => {
      window.matchMedia("(max-width: 499px)").removeListener(handler);
    }
  }, [mobile]);

  if (!availableWebinar) {
    return (
      <div className={classes.SpecialWebinarPage}>
        <div className={classes.SpecialWebinarPage_Header}>
          <h1>Mohon Maaf</h1>
          {!availableWebinar ? null : <PostElement
            type={'Image'}
            src={`https://files.ezstoreapp.com/content/finance/webinar101020.jpg`}
          />}
          <p>
            Belum ada Live Webinar yang akan diselenggarakan dalam waktu dekat.
            <br /><br />
            Untuk info Webinar selanjutnya akan disampaikan di grup Telegram Belajar Saham.
            <br /><br />
            Salam Hangat,<br /><br />
            <strong>R.B.</strong><br /><br />
          </p>

        </div>
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className={classes.Centered}>
        <LoadingSpinner />
      </div>
    )
  }

  // ====================== NAME INPUT HANDLER ======================= //
  const nameInputHandler = (event) => {
    const { value } = event.target;
    setName(value);
  }

  // ====================== PHONE INPUT HANDLER ======================= //
  const phoneInputHandler = (event) => {
    const { value } = event.target;
    setPhone(value);
  }

  // ====================== EMAIL INPUT HANDLER ======================= //
  const emailInputHandler = (event) => {
    const { value } = event.target;
    setEmail(value);
  }

  // ====================== IG INPUT HANDLER ======================= //
  const igInputHandler = (event) => {
    const { value } = event.target;
    setIg(value);
  }

  // ====================== LV INPUT HANDLER ======================= //
  const lvInputHandler = (event) => {
    const { value } = event.target;
    setLv(value);
  }

  // ====================== IS VALUE INPUT HANDLER ======================= //
  const isValueInputHandler = (event) => {
    const { value } = event.target;
    setIsValue(value);
  }

  const submitHandler = async () => {
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRegex = /^\d+$/

    if (name.length < 3 ||
      (phone.length < 8 || phone.length > 15 || !phoneRegex.test(phone)) ||
      (!emailRegex.test(email.toLowerCase())) ||
      (ig.length < 4)
    ) {
      return alert('Form Pendaftaran belum terisi dengan benar. Harap isi terlebih dahulu.')
    }

    try {
      setIsLoading(true);
      await dispatch(specialWebinarReg(name, phone, email, ig, lv, isValue));
      setRegistered(true);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  if (registered) {
    return (
      <div className={classes.SpecialWebinarPage}>
        <div className={classes.SpecialWebinarPage_Header}>
          <h1>Pendaftaran Berhasil</h1>
          {/* <PostElement
            type={'Image'}
            src={`https://files.ezstoreapp.com/content/finance/webinar101020.jpg`}
          /> */}
          <p>
            Mohon tunggu konfirmasi melalui WhatsApp ya.
            <br /><br />
            Salam Hangat,<br /><br />
            <strong>R.B.</strong><br /><br />
          </p>

        </div>
      </div>
    )
  }

  return (

    <>

      <div className={classes.SpecialWebinarPage}>

        <div className={classes.SpecialWebinarPage_Header}>
          <h1>10K Giveaway Webinar</h1>
          {/* <PostElement
            type={'Image'}
            src={`https://files.ezstoreapp.com/content/finance/webinar101020.jpg`}
          /> */}
          <p>
            Halo!<br /><br />
            Selamat ya sudah terpilih untuk mengikuti 10K Giveaway Webinar dengan tema <strong>Dasar Investasi Saham</strong>.
            <br />
          </p>
          <p>Pada webinar ini, kita akan belajar mengenai <strong>dasar-dasar dari ilmu investasi saham</strong>. Pokok bahasan kita meliputi:</p>
          <p>
            1. Apa itu saham ?<br />
            2. Kenapa perusahaan menerbitkan saham ?<br />
            3. Kenapa saham harganya naik ?<br />
            4. Metode pemilihan saham<br />
            <br />
          </p>
          <p>
            Mohon agar bisa mengisi <strong>Form Pendaftaran</strong> di bawah ya untuk mengikuti <strong>Special 10K Webinar</strong> ini.
          </p>

        </div>

        <div className={classes.SpecialWebinarPage_Form}>

          <h2>Form Pendaftaran</h2>

          <div className={classes.SpecialWebinarPage_Header}>
            <TextField
              label="Nama"
              type="text"
              style={{ width: '80vw', marginBottom: 10, marginLeft: '10vw' }}
              value={name}
              onChange={nameInputHandler}
              inputProps={{ maxLength: 30 }}
            />
            <TextField
              label="Akun Instagram (tanpa @ di depan)"
              type="text"
              style={{ width: '80vw', marginBottom: 10, marginLeft: '10vw' }}
              value={ig}
              onChange={igInputHandler}
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              label="No WhatsApp (tanpa + di depan)"
              type="tel"
              style={{ width: '80vw', marginBottom: 10, marginLeft: '10vw' }}
              value={phone}
              onChange={phoneInputHandler}
              inputProps={{ maxLength: 15 }}
            />
            <TextField
              label="Email"
              type="email"
              style={{ width: '80vw', marginBottom: 10, marginLeft: '10vw' }}
              value={email}
              onChange={emailInputHandler}
              inputProps={{ maxLength: 150 }}
            />
            <FormControl style={{ width: '80vw', marginLeft: '10vw', marginBottom: 10 }}>
              <InputLabel>Sudah pernah Investasi Saham ?</InputLabel>
              <Select
                value={lv}
                onChange={lvInputHandler}
              >
                <MenuItem value={0}>Belum pernah sama sekali.</MenuItem>
                <MenuItem value={1}>Baru-baru ini mulai investasi.</MenuItem>
                <MenuItem value={2}>Sudah lebih dari 2 tahun.</MenuItem>
                <MenuItem value={9}>Sudah paham dan ahli berinvestasi.</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ width: '80vw', marginLeft: '10vw', marginBottom: 10 }}>
              <InputLabel>Harapan ikut Webinar ?</InputLabel>
              <Select
                value={isValue}
                onChange={isValueInputHandler}
              >
                <MenuItem value={true}>Mau belajar "Investasi Saham".</MenuItem>
                <MenuItem value={false}>Mau belajar "Spekulasi Saham".</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div>
            <p>
              <strong>Pastikan data diri yang kamu isi sudah benar</strong>, karena link Webinar akan dikirim melalui email / WA.
            <br />
            </p>
          </div>


          <div style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex'
          }}>
            <Button
              variant="contained"
              size="medium"
              style={{ backgroundColor: Colors.royal, color: Colors.primary, width: '80vw', marginTop: 0, marginBottom: 30 }}
              onClick={submitHandler}
            >
              Daftar Gratis
          </Button>
          </div>

        </div>

        <div>
          <p>
            Sampai jumpa di <strong>10K Giveaway Webinar</strong>.
          </p>
        </div>

      </div>

      <ScrollDownButton />

    </>

  );

}

export default SpecialWebinarPage;
