import api from '../../constants/Api';
import { LOGOUT } from '../actions/authAction';

export const SET_COURSES = 'SET_COURSES';
export const SET_SELECTED_COURSE = 'SET_SELECTED_COURSE';
export const COMPLETED_COURSE = 'COMPLETED_COURSE';


// =========================== SET COURSES ========================== //
export const setCourses = (courses) => {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_COURSES,
            courses: courses
        })
    }
}

// =========================== SET SELECTED COURSE ========================== //
export const setSelectedCourse = (course, id) => {
    return async (dispatch, getState) => {
        dispatch({
            type: SET_SELECTED_COURSE,
            selectedCourse: course,
            id: id
        })
    }
}

// =========================== GET COURSES IN A CAT ========================== //
export const getCoursesInACat = (cat) => {
    return async (dispatch, getState) => {

        const token = getState().auth.token;

        try {
            const response = await fetch(
                api.bnkv + `/course/${cat}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                }
            );

            if (!response.ok) {
                dispatch({ type: LOGOUT });
                console.log(`Log out...`);
                throw new Error('Tidak dapat terkoneksi dengan server.')
            };

            const resData = await response.json();
            // console.log(resData);

            dispatch(setCourses(
                resData.courses
            ));
            dispatch(setSelectedCourse(
                resData.selectedCourse
            ));

        } catch (err) {
            throw err;
        }
    }
};

// =========================== GET A COURSE DETAILS ========================== //
export const getCourseDetails = (id) => {
    return async (dispatch, getState) => {

        const token = getState().auth.token;
        try {
            const response = await fetch(
                api.bnkv + `/course/details/${id}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                }
            );

            if (!response.ok) {
                dispatch({ type: LOGOUT });
                console.log(`Log out...`);
                throw new Error('Tidak dapat terkoneksi dengan server.')
            };

            const resData = await response.json();
            // console.log(resData);

            dispatch(setSelectedCourse(
                resData.selectedCourse
            ));

        } catch (err) {
            throw err;
        }
    }
};


// =========================== ADD COMPLETED COURSE ========================== //
export const addCompletedCourse = (id) => {
    return async (dispatch, getState) => {

        const token = getState().auth.token;
        try {
            const response = await fetch(
                api.bnkv + `/course/completed/${id}`,
                {
                    method: 'PATCH',
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                }
            );

            if (!response.ok) {
                dispatch({ type: LOGOUT });
                throw new Error('Tidak dapat terkoneksi dengan server.');
            };

            // const resData = await response.json();
            // console.log(resData);

            dispatch({
                type: COMPLETED_COURSE,
                id: id
            });

        } catch (err) {
            throw err;
        }
    }
};
