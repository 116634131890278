import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';
// import TimerIcon from '@material-ui/icons/Timer';
// import TextField from '@material-ui/core/TextField';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';

// import LoadingSpinner from '../components/UI/spinner/LoadingSpinner';
// import TelegramButton from '../../components/UI/buttons/TelegramButton';
// import LoginModal from '../../components/UI/modal/LoginModal';
import LoadingSpinner from '../../components/UI/spinner/LoadingSpinner';

// import Ask from '../../assets/images/ask.png';
// import Profit from '../../assets/images/profit.jpg';
// import Books from '../../assets/images/books.png';

// import PremiumFee from '../../assets/images/premium-fee.jpg';
import Welcome from '../../assets/images/welcome.jpg';

import Colors from '../../constants/Colors';

import classes from './ThankYouPage.module.css';
import { refreshToken } from '../../redux/actions/authAction';
import { getInvoiceDetails } from '../../redux/actions/paymentAction';


const ThankYouPage = (props) => {

  const dispatch = useDispatch();

  const token = useSelector(state => state.auth.token);

  // console.log('TOKEN === ', token);

  const [isLoading, setIsLoading] = useState(false);
  const [mobile, setMobile] = useState(window.matchMedia("(max-width: 499px)").matches);
  const [desktop, setdesktop] = useState(window.matchMedia("(min-width: 900px)").matches);

  // const [classPackage, setclassPackage] = useState(1);
  // const [showModal, setshowModal] = useState(false);

  // const isPremium = classPackage === 1;
  // const classId = `60850ee7f0e9ff2694539ac1`;



  // ============================= WINDOW WIDTH CHANGE LISTENER ======================== //
  useEffect(() => {
    const handler = e => setMobile(e.matches);
    window.matchMedia("(max-width: 499px)").addListener(handler);
    return () => {
      window.matchMedia("(max-width: 499px)").removeListener(handler);
    }
  }, [mobile]);

  // ============================= WINDOW WIDTH CHANGE LISTENER ======================== //
  useEffect(() => {
    const handler = e => setdesktop(e.matches);
    window.matchMedia("(min-width: 900px)").addListener(handler);
    return () => {
      window.matchMedia("(min-width: 900px)").removeListener(handler);
    }
  }, [mobile]);

  // ============================= IF NO TOKEN GO TO LOGIN ======================== //
  // useEffect(() => {
  //   if (!token) {
  //     props.history.push('/course/login');
  //   }
  // }, [props.history, token])

  // ============================= BUTTONS ======================== //
  const learnSaham = async () => {
    try {
      const selectedPageMode = props.location.pathname.split('/').pop();
      setIsLoading(true);
      if (token) {
        window.scrollTo(0, 0);
        await dispatch(refreshToken());
        const invoiceDetails = await dispatch(getInvoiceDetails(selectedPageMode));
        props.history.push(`/${invoiceDetails.type}/${invoiceDetails.code}`);
      } else {
        props.history.push('/course/login');
      }
    } catch (err) {
      props.history.push('/course/list');
    }
  }


  if (isLoading) {
    return (
      <div className={classes.Centered}>
        <LoadingSpinner />
      </div>
    )
  }



  return (

    <>

      <div className={classes.ThankYouPage}>


        <div className={classes.ThankYouPage_Header} style={{ marginBottom: 0, paddingBottom: 30 }}>
          <h1 style={{ marginBottom: 0 }}>Terima kasih!</h1>
          <p>Kamu telah memilih langkah yang tepat dalam memulai perjalanan investasi kamu. Yuk kita mulai belajar :)</p>
          {mobile ? null :
            <div style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex'
            }}>
              <Button
                variant="contained"
                size="medium"
                style={{ backgroundColor: Colors.royal, color: Colors.primary, width: '80vw', marginTop: 0, marginBottom: 30, maxWidth: 400 }}
                onClick={learnSaham}
              >
                Mulai Belajar
              </Button>
            </div>}
          <div style={{ display: 'flex', width: `100vw`, justifyContent: 'center', alignItems: 'center' }}>
            <img style={{ width: '100vw', alignSelf: 'center' }} src={Welcome} alt={'Welcome'} />
          </div>
          {mobile ?
            <div style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex'
            }}>
              <Button
                variant="contained"
                size="medium"
                style={{ backgroundColor: Colors.royal, color: Colors.primary, width: '80vw', marginTop: 50, marginBottom: 30, maxWidth: 400 }}
                onClick={learnSaham}
              >
                Mulai Belajar
              </Button>
            </div> : null}
        </div>

      </div>





    </>

  );
}

export default ThankYouPage;
