import { GET_ONE_ARTICLE, GET_TOP_ARTICLES, GET_ALL_ARTICLES } from "../actions/articleAction";

const initialState = {

    allArticles: [],
    topArticles: [],
    financeArticles: [],
    techArticles: [],
    currentArticle: null

};

const articleReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_ALL_ARTICLES: {
            return {
                ...state,
                allArticles: action.articles
            }
        }

        case GET_TOP_ARTICLES: {
            return {
                ...state,
                topArticles: action.articles
            }
        }

        case GET_ONE_ARTICLE: {
            return {
                ...state,
                currentArticle: action.article
            }
        }

        default:
            return state;
    }
}

export default articleReducer;