import React from 'react';
import Colors from '../../../constants/Colors';

const Header2 = (props) => {
    return (
        <h1 style={{
            color: props.color || Colors.white,
            fontWeight: props.fontWeight || null,
            fontSize: props.fontSize || 23,
            marginLeft: props.marginLeft || '10vw',
            marginRight: props.marginRight || '10vw',
            textAlign: "left",
            lineHeight: 1.7,
            marginBottom: 30
        }}>
            {props.src}
        </h1>
    )
};

export default Header2;