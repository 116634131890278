import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';

import Modal from './Modal';
// import Stock from '../../assets/images/features/Report.png';

import Colors from '../../../constants/Colors';

import classes from './LoginModal.module.css';

const LoginModal = props => {

    const loginHandler = () => {
        props.loginHandler()
    }

    const registerHandler = () => {
        props.registerHandler()
    }

    return (
        <Modal show={props.show} close={props.close}>
            <>
                <div className={classes.Container}>
                    <div className={classes.CloseButton} onClick={props.close} >
                        <CloseIcon />
                    </div>

                    <div style={{
                        marginTop: 20,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        flexDirection: 'column'
                    }}>
                        <h1>Kamu Belum Login</h1>
                        <p>Untuk melanjutkan, kamu harus <strong>Login</strong> terlebih dahulu.</p>
                        <div style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex'
                        }}>
                            <Button
                                variant="contained"
                                size="medium"
                                style={{ backgroundColor: Colors.royal, color: Colors.primary, width: '80vw', marginTop: 10, marginBottom: 10, maxWidth: 400 }}
                                onClick={loginHandler}
                            >
                                Login
                            </Button>
                        </div>
                        <p>Belum punya akun? Kamu bisa <strong>Registrasi</strong> disini.</p>
                        <div style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex'
                        }}>
                            <Button
                                variant="contained"
                                size="medium"
                                style={{ backgroundColor: Colors.royal, color: Colors.primary, width: '80vw', marginTop: 10, marginBottom: 50, maxWidth: 400 }}
                                onClick={registerHandler}
                            >
                                REGISTRASI
                            </Button>
                        </div>
                    </div>

                </div>
            </>
        </Modal>
    )
}

export default LoginModal;