import React from 'react';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import classes from './WAButton.module.css';

const WAButton = props => {
    const phone = props.phone;
    return (
        <div className={classes.WA} >
            <a href={`${phone}`} ><WhatsAppIcon style={{color: '#fff', fontSize: 40}} /></a>
        </div>
    )
};

export default WAButton;
