import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import TimerIcon from '@material-ui/icons/Timer';

import LoadingSpinner from '../components/UI/spinner/LoadingSpinner';
import PostElement from '../components/post/PostElement';
import Footer from '../components/post/Footer/Footer';

import { postObjectToArray } from '../constants/Functions';
import Colors from '../constants/Colors';

import {
  getOneArticle,
  // postArticle
} from '../redux/actions/articleAction';

import classes from './PostPage.module.css';


const PostPage = (props) => {

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  // const [showDetails, setShowDetails] = useState('');
  const [mobile, setMobile] = useState(window.matchMedia("(max-width: 499px)").matches);

  // ============================= WINDOW WIDTH CHANGE LISTENER ======================== //
  useEffect(() => {
    const handler = e => setMobile(e.matches);
    window.matchMedia("(max-width: 499px)").addListener(handler);
    return () => {
      window.matchMedia("(max-width: 499px)").removeListener(handler);
    }
  }, [mobile]);

  // ============================= FETCH ARTICLE DATA (CALLBACK) ======================== //
  const fetchArticleData = useCallback(async () => {
    try {
      const articleURL = props.location.pathname.split('/').pop();
      await dispatch(getOneArticle(articleURL));
      await setIsLoading(false);
    } catch (err) {
      props.history.replace("/");
    }
  }, [dispatch, props.location.pathname, props.history]);

  // ============================= AUTO FETCH ARTICLE DATA ======================== //
  useEffect(() => {
    let fetchingData = true;
    if (fetchingData) {
      fetchArticleData()
    }
  }, [fetchArticleData]);

  const chosenArticle = useSelector(state => state.article.currentArticle);

  let postElementsArray;
  if (chosenArticle) {
    postElementsArray = postObjectToArray(chosenArticle.elements, chosenArticle.elementIds);
  }

  // const postingArticle = async () => await dispatch(postArticle())

  if (isLoading) {
    return (
      <div className={classes.Centered}>
        <LoadingSpinner />
      </div>
    )
  }

  return (

    <>

      <div className={classes.PostPage}>

        <div className={classes.PostPage_Header}>

          {/* <h1 onClick={postingArticle}>POST</h1> */}

          <h1>{chosenArticle.title}</h1>
          <p style={{ color: Colors.white }}>Posted on &nbsp; {moment(chosenArticle.date).locale('id').format('LL')}</p>
          <p>
            Durasi baca &nbsp; : &nbsp; {chosenArticle.duration} &nbsp; menit
            <br />
            Dibaca oleh &nbsp; : &nbsp; {chosenArticle.reader} &nbsp; orang
          </p>

        </div>

        <div className={classes.PostPage_Body}>

          {postElementsArray.map(Elem => (
            <PostElement
              key={Elem.id}
              type={Elem.type}
              src={Elem.src}
            />
          ))}

          <Footer />

        </div>

      </div>

    </>

  );
}

export default PostPage;
