import React from 'react';
import {
    useSelector
} from 'react-redux';


import CourseItem from './CourseItem';

import { groupingCourse } from '../../constants/Functions';

import classes from './CourseSection.module.css';

const CourseSection = (props) => {

    const completedCourses = useSelector(state => state.auth.courses);
    const badges = useSelector(state => state.auth.badges);
    const courses = useSelector(state => state.course.courses);
    const sectionedCourses = groupingCourse(courses);

    const clickHandler = (c) => {
        props.clickHandler(c)
    }

    return (
        sectionedCourses.map(sec => {
            return (
                <div
                    className={classes.CourseSection}
                    key={sec.key}
                >
                    <h1>{sec.key}</h1>
                    {sec.data.map((cour, index) => {
                        return (
                            <CourseItem
                                onClick={() => clickHandler(cour._id)}
                                indexNumber={index + 1}
                                title={cour.title}
                                key={cour.no}
                                completed={completedCourses.includes(cour._id)}
                                locked={!badges.includes(cour.group)}
                                id={cour._id}
                            />
                        )
                    })}
                </div>
            )
        })
    )
};

export default CourseSection;

