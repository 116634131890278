import React from 'react';

import CourseItem from '../courses/CourseItem';

import classes from './WebinarSection.module.css';

const WebinarSection = (props) => {

    const allEpisodes = props.episodes;

    const clickHandler = (c) => {
        props.clickHandler(c)
    }

    return (
        <div className={classes.WebinarSection}>
            {allEpisodes.map((cour, index) => {
                return (
                    <CourseItem
                        onClick={() => clickHandler(index)}
                        indexNumber={index + 1}
                        title={cour.title}
                        key={cour.no}
                        completed={false}
                        locked={false}
                        id={cour.no}
                    />
                )
            })}
        </div>
    )
};

export default WebinarSection;

