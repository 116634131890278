import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';
// import TimerIcon from '@material-ui/icons/Timer';

// import LoadingSpinner from '../components/UI/spinner/LoadingSpinner';
import ImageModal from '../components/UI/modal/ImageModal';
import TelegramButton from '../components/UI/buttons/TelegramButton';


import WMI from '../assets/images/wmi.jpg';
import WPPE from '../assets/images/wppe.jpg';

import Colors from '../constants/Colors';

import classes from './HomePage.module.css';
import { getTopArticles } from '../redux/actions/articleAction';

import { importantData } from '../constants/Data';

const HomePage = (props) => {

  const dispatch = useDispatch();

  const topArticles = useSelector(state => state.article.topArticles);

  const [isLoading, setIsLoading] = useState(true);
  const [mobile, setMobile] = useState(window.matchMedia("(max-width: 499px)").matches);
  const [artNumber, setArtNumber] = useState(0);
  const [modalShow, setmodalShow] = useState(false);
  const [modalImage, setmodalImage] = useState(WMI);


  // ============================= WINDOW WIDTH CHANGE LISTENER ======================== //
  useEffect(() => {
    const handler = e => setMobile(e.matches);
    window.matchMedia("(max-width: 499px)").addListener(handler);
    return () => {
      window.matchMedia("(max-width: 499px)").removeListener(handler);
    }
  }, [mobile]);

  // ============================= FETCH TOP ARTICLES DATA (CALLBACK) ======================== //
  const fetchTopArticlesData = useCallback(async () => {
    await dispatch(getTopArticles());
    await setIsLoading(false);
  }, [dispatch]);


  // ============================= AUTO FETCH TOP ARTICLES DATA ======================== //
  useEffect(() => {
    let fetchingData = true;
    if (fetchingData) {
      fetchTopArticlesData()
    }
  }, [fetchTopArticlesData]);

  const autoChangeTopArticle = () => {
    if (artNumber < 4) {
      setArtNumber(artNumber + 1);
    } else {
      setArtNumber(0);
    }
  };

  setTimeout(() => autoChangeTopArticle(), 5000);

  // ============================= BUTTONS ======================== //
  const learnSaham = () => {
    window.scrollTo(0, 0);
    props.history.push('/course/stock/premium/home');
  }

  const showWMI = () => {
    setmodalImage(WMI);
    setmodalShow(true);
  }

  const showWPPE = () => {
    setmodalImage(WPPE);
    setmodalShow(true);
  }


  return (

    <>

      <ImageModal
        show={modalShow}
        close={() => setmodalShow(false)}
        imgUrl={modalImage}
      />

      <div className={classes.HomePage}>

        <TelegramButton
          url={importantData.telegram}
        />

        <div className={classes.HomePage_Header}>
          <h1 className={classes.HomePage_Header_Hello} style={{ color: Colors.accent }}>Hello,<br />Selamat datang.</h1>
          <p className={classes.HomePage_Header_Explanation} style={{ color: Colors.primary }}>Saya <strong>Raymond Bonakapvi,</strong> seorang&nbsp;
          <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.merriam-webster.com/dictionary/technopreneur"
              style={{ color: Colors.primary, textDecoration: 'none' }}
            >
              <strong>Technopreneur</strong>
            </a>

            &nbsp; dan&nbsp;

            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.investopedia.com/terms/i/investor.asp"
              style={{ color: Colors.primary, textDecoration: 'none' }}
            >
              <strong>Investor</strong>
            </a>
            &nbsp; yang memegang sertifikasi <strong style={{ color: Colors.primary, textDecoration: 'underline', cursor: 'pointer' }} onClick={showWPPE}><strong>WPPE (Stock Broker)</strong></strong> dan <strong style={{ color: Colors.primary, textDecoration: 'underline', cursor: 'pointer' }} onClick={showWMI}>WMI (Investment Manager)</strong>.
          </p>
          <p className={classes.HomePage_Header_Explanation} style={{ color: Colors.primary }}>Di website ini, saya akan membagikan berbagai macam ilmu praktis terkait <strong>Investasi Saham</strong> yang sudah teruji hasil dan implementasinya.
          </p>
          <p className={classes.HomePage_Header_Explanation} style={{ color: Colors.primary }}>Untuk kamu yang mau belajar, yuk langsung click tombol <strong>Kelas Investasi Saham</strong> di bawah ini.
          </p>

          <div style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex'
          }}>
            <Button
              variant="contained"
              size="medium"
              style={{ backgroundColor: Colors.royal, color: Colors.primary, width: '80vw', marginTop: 30, marginBottom: 10, maxWidth: 400 }}
              onClick={learnSaham}
            >
              Kelas Investasi Saham
          </Button>
          </div>

        </div>

        <div className={classes.HomePage_Body}>
          <div className={classes.HomePage_Body_Posts}>
            <h1>Artikel Teratas</h1>
            {isLoading ? null
              :
              <div>
                <Link style={{ color: Colors.gold }} to={'/' + topArticles[artNumber].uri} onClick={() => window.scrollTo(0, 0)}>
                  <h2 style={{ color: Colors.gold }}>{topArticles[artNumber].title}</h2>
                </Link>
                <div style={{
                  display: 'flex',
                  marginLeft: '10vw',
                  alignItems: 'center'
                }}>
                  <VisibilityIcon style={{ color: Colors.electric }} />
                  <p style={{ color: Colors.electric, marginLeft: 20 }}>{topArticles[artNumber].reader} &nbsp; orang</p>
                </div>
                <p style={{ color: Colors.white }}>{topArticles[artNumber].headline}</p>
              </div>}

          </div>
        </div>

      </div>

    </>

  );
}

export default HomePage;
