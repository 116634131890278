import React from 'react';

import Colors from '../../constants/Colors';

import classes from './ParticipantItem.module.css';

const ParticipantItem = (props) => {
    return (
        // <a
        //     style={{ color: Colors.gold, textDecoration: 'none' }}
        // >
        <div className={classes.ParticipantItem}>
            <h3 style={{
                textDecoration: 'underline',
                textDecorationColor: Colors.gold
            }}>
                {props.name}
            </h3>
            <p>{props.ig}</p>
            <p>{props.phone}</p>
            <p>{props.email}</p>
            <p>{props.value ? 'Investor' : 'Speculator'} - Level {props.lv}</p>
            <br />
        </div>
        // </a>
    )
}

export default ParticipantItem;