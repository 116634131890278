import api from '../../constants/Api';
// import { dasarAnalisaSaham } from '../../constants/Objects';

import { LOGOUT } from '../actions/authAction';

export const SET_PARTICIPANTS = 'SET_PARTICIPANTS';
export const SET_RECORDINGS = 'SET_RECORDINGS';


// =========================== GET WEBINAR RECORDINGS ========================== //
export const getWebinarRecordings = (aId, keyword, webinarId) => {
    return async (dispatch, getState) => {

        const token = getState().auth.token;

        try {
            const response = await fetch(
                api.bnkv + `/webinar/get/all`,
                {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            if (!response.ok) {
                dispatch({ type: LOGOUT });
                throw new Error('Tidak dapat terkoneksi dengan server.')
            };

            const resData = await response.json();
            // console.log(resData);

            dispatch({
                type: SET_RECORDINGS,
                recordings: resData.webinars
            });

        } catch (err) {
            throw err;
        }
    }
};

// =========================== GET MACRO UPDATE RECORDINGS ========================== //
export const getMacroUpdate = () => {
    return async (dispatch, getState) => {

        const token = getState().auth.token;

        try {
            const response = await fetch(
                api.bnkv + `/webinar/get/macro`,
                {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            if (!response.ok) {
                dispatch({ type: LOGOUT });
                throw new Error('Tidak dapat terkoneksi dengan server.')
            };

            const resData = await response.json();
            // console.log(resData);

            dispatch({
                type: SET_RECORDINGS,
                recordings: resData.webinars
            });

        } catch (err) {
            throw err;
        }
    }
};


// =========================== WEBINAR REGISTRATION ========================== //
export const webinarReg = (name, phone, email, ig, lv, value) => {
    return async (dispatch, getState) => {

        const webinarId = "21112020";
        const bodyData = {
            name: name,
            phone: phone,
            email: email,
            ig: ig,
            lv: lv,
            value: value
        };

        // console.log(webinarId);
        // console.log(bodyData);

        try {
            const response = await fetch(
                api.bnkv + `/webinar/${webinarId}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify(bodyData)
                }
            );

            if (!response.ok) {
                throw new Error('Tidak dapat terkoneksi dengan server.')
            };

            // const resData = await response.json();
            // console.log(resData);

        } catch (err) {
            throw err;
        }
    }
};


// =========================== WEBINAR PARTICIPANTS LIST ========================== //
export const getWebinarParticipants = (aId, keyword, webinarId) => {
    return async (dispatch, getState) => {

        const bodyData = {
            aId: aId,
            keyword: keyword
        };

        // console.log(webinarId);
        // console.log(bodyData);

        try {
            const response = await fetch(
                api.bnkv + `/admin/webinar-list/${webinarId}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify(bodyData)
                }
            );

            if (!response.ok) {
                throw new Error('Tidak dapat terkoneksi dengan server.')
            };

            const resData = await response.json();
            // console.log(resData);

            dispatch({
                type: SET_PARTICIPANTS,
                webinarId: webinarId,
                participants: resData.participants
            });

        } catch (err) {
            throw err;
        }
    }
};


// =========================== SPECIAL WEBINAR REGISTRATION ========================== //
export const specialWebinarReg = (name, phone, email, ig, lv, value) => {
    return async (dispatch, getState) => {

        const webinarId = "10K";
        const bodyData = {
            name: name,
            phone: phone,
            email: email,
            ig: ig,
            lv: lv,
            value: value
        };

        try {
            const response = await fetch(
                api.bnkv + `/webinar/special/${webinarId}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify(bodyData)
                }
            );

            if (!response.ok) {
                throw new Error('Tidak dapat terkoneksi dengan server.')
            };

            const resData = await response.json();
            console.log(resData);

        } catch (err) {
            throw err;
        }
    }
};
