import React from 'react';
import Colors from '../../../constants/Colors';

const Header2 = (props) => {
    return (
        <div>
            <p style={{
                color: Colors.white,
                marginBottom: 40,
                fontSize: 17
            }}>
                Sampai jumpa di Post berikut nya :)
            </p>
            <p style={{
                color: Colors.white,
                marginBottom: 40,
                lineHeight: 1.7
            }}>
                Ohiya, jangan lupa follow Instagram &nbsp;
                <strong style={{ color: Colors.electric }}>
                    <a
                        style={{ color: Colors.electric }}
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://www.instagram.com/rbonakapvi/">

                        @rbonakapvi
                    </a>
                </strong>
                &nbsp; untuk selalu update info terkini seputar <strong>Bisnis, Investasi & Teknologi.</strong>
            </p>
        </div>
    )
};

export default Header2;