import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import LoadingSpinner from '../components/UI/spinner/LoadingSpinner';
import ScrollDownButton from '../components/UI/buttons/ScrollDownButton';

import Colors from '../constants/Colors';

import { courseLogin, courseRegister } from '../redux/actions/authAction';
import { setCoursePaymentUrl } from '../redux/actions/paymentAction';

import classes from './CourseLoginPage.module.css';

const CourseLoginPage = (props) => {

    const dispatch = useDispatch();

    const isPaying = useSelector(state => state.auth.isPaying);
    const classId = useSelector(state => state.payment.classId);
    // const token = useSelector(state => state.auth.token);
    // const badges = useSelector(state => state.auth.badges);


    const [isLoading, setIsLoading] = useState(false);
    // const [showDetails, setShowDetails] = useState('');
    const [mobile, setMobile] = useState(window.matchMedia("(max-width: 499px)").matches);

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [pword, setPword] = useState('');
    const [confirmPword, setconfirmPword] = useState('');
    const [lv, setLv] = useState(0);
    const [isValue, setIsValue] = useState(true);

    const [pageMode, setPageMode] = useState('login');

    // ============================= WINDOW WIDTH CHANGE LISTENER ======================== //
    useEffect(() => {
        const handler = e => setMobile(e.matches);
        window.matchMedia("(max-width: 499px)").addListener(handler);
        return () => {
            window.matchMedia("(max-width: 499px)").removeListener(handler);
        }
    }, [mobile]);

    useEffect(() => {
        const selectedPageMode = props.location.pathname.split('/').pop();
        if (selectedPageMode === 'login') {
            setPageMode(selectedPageMode);
        } else if (selectedPageMode === 'register') {
            setPageMode(selectedPageMode);
        } else {
            setPageMode('register');
        }
    }, [props.location.pathname]);


    if (isLoading) {
        return (
            <div className={classes.Centered}>
                <LoadingSpinner />
            </div>
        )
    }

    // =================== CHANGE PAGE MODE ================== //

    const goToRegister = () => {
        window.scrollTo(0, 0);
        props.history.push('/course/register');
    }

    const goToLogin = () => {
        window.scrollTo(0, 0);
        props.history.push('/course/login');
    }


    // ====================== NAME INPUT HANDLER ======================= //
    const nameInputHandler = (event) => {
        const { value } = event.target;
        setName(value);
    }

    // ====================== EMAIL INPUT HANDLER ======================= //
    const emailInputHandler = (event) => {
        const { value } = event.target;
        setEmail(value);
    }

    // ====================== PHONE INPUT HANDLER ======================= //
    const phoneInputHandler = (event) => {
        const { value } = event.target;
        setPhone(value);
    }

    // ====================== IG INPUT HANDLER ======================= //
    const passwordInputHandler = (event) => {
        const { value } = event.target;
        setPword(value);
    }

    // ====================== IG INPUT HANDLER ======================= //
    const confirmPasswordInputHandler = (event) => {
        const { value } = event.target;
        setconfirmPword(value);
    }

    // ====================== LV INPUT HANDLER ======================= //
    const lvInputHandler = (event) => {
        const { value } = event.target;
        setLv(value);
    }

    // ====================== IS VALUE INPUT HANDLER ======================= //
    const isValueInputHandler = (event) => {
        const { value } = event.target;
        setIsValue(value);
    }

    // ====================== HANDLING REGISTRATION ======================= //
    const registerHandler = async () => {
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const phoneRegex = /^\d+$/

        if (name.length < 3 ||
            (phone.length < 8 || phone.length > 15 || !phoneRegex.test(phone)) || phone.toString()[0] !== '0' ||
            (!emailRegex.test(email.toLowerCase())) ||
            (pword.length < 8)
        ) {
            return alert('Form Pendaftaran belum terisi dengan benar. Harap isi terlebih dahulu.');
        }
        if (pword !== confirmPword) {
            return alert('Konfirmasi password tidak sesuai....');
        }

        try {
            setIsLoading(true);
            if (isPaying) {
                /// IS PAYING === TRUE
                if (classId === 'free') {
                    /// IF PAYING FREE
                    console.log('REGISTER >> FREE');
                    await dispatch(courseRegister(name, phone, email, pword, lv, isValue));
                    props.history.push('/course/list');
                } else {
                    const callResponse = await dispatch(courseRegister(name, phone, email, pword, lv, isValue));
                    // console.log('REGISTER RESPONSE == ', callResponse);
                    if (callResponse.badges.includes('sp101')) {
                        /// IF ALREADY PAID
                        console.log('REGISTER >> PREMIUM >> ALREADY PAID');
                        props.history.push('/course/list');
                    } else {
                        /// IF PAYING PREMIUM
                        console.log('REGISTER >> PREMIUM >> PAYING');
                        console.log('LOGIN >> PREMIUM >> PAYING');
                        try {
                            const xenditUrl = await dispatch(setCoursePaymentUrl());
                            window.location.href = xenditUrl;
                        } catch (err) {
                            return alert('Terjadi kegagalan koneksi dengan server...')
                        }
                    }
                }
            } else {
                /// IF NOT PAYING ANYTHING
                await dispatch(courseRegister(name, phone, email, pword, lv, isValue));
                props.history.push('/course/list');
            }
        } catch (err) {
            setIsLoading(false);
            return alert('No WhatsApp atau Email sudah terdaftar. Mohon gunakan data yang berbeda.')
        }
    }

    // ====================== HANDLING LOGIN ======================= //
    const loginHandler = async () => {
        const phoneRegex = /^\d+$/

        if (pword.length < 8 || pword.length > 15 ||
            (phone.length < 8 || phone.length > 15 || !phoneRegex.test(phone))
        ) {
            return alert('Mohon isi dengan No WhatsApp dan Password yang sudah terdaftar sebelumnya.')
        }

        try {
            setIsLoading(true);
            if (isPaying) {
                /// IF IS PAYING === TRUE
                if (classId === 'free') {
                    /// IF PAYING FREE
                    await dispatch(courseLogin(phone, pword));
                    props.history.push('/course/list');
                    console.log('LOGIN >> FREE');
                } else {
                    const callResponse = await dispatch(courseLogin(phone, pword));
                    // console.log('LOGIN RESPONSE == ', callResponse);
                    if (callResponse.badges.includes('sp101')) {
                        /// IF ALREADY PAID
                        props.history.push('/course/list');
                        console.log('LOGIN >> PREMIUM >> ALREADY PAID');
                    } else {
                        /// IF PAYING PREMIUM
                        console.log('LOGIN >> PREMIUM >> PAYING');
                        try {
                            const xenditUrl = await dispatch(setCoursePaymentUrl());
                            window.location.href = xenditUrl;
                        } catch (err) {
                            return alert('Terjadi kegagalan koneksi dengan server...')
                        }
                    }
                }
            } else {
                await dispatch(courseLogin(phone, pword));
                props.history.push('/course/list');
            }
        } catch (err) {
            setIsLoading(false);
            return alert('No WhatsApp atau Password yang kamu masukkan salah. Mohon periksa kembali.')
        }
    }


    if (pageMode === 'login') {
        return (

            <>

                <div className={classes.CourseLoginPage}>

                    <div className={classes.CourseLoginPage_Header}>
                        <h1>{isPaying ? `Login dahulu untuk melanjutkan` : `Kelas Investasi Saham @rbonakapvi`}</h1>
                    </div>

                    <div className={classes.CourseLoginPage_Form}>

                        <h2>Login</h2>

                        <div className={classes.CourseLoginPage_Header}>
                            <TextField
                                label="No WhatsApp"
                                type="tel"
                                style={{ width: '80%', marginBottom: 10, marginLeft: '10%' }}
                                value={phone}
                                onChange={phoneInputHandler}
                                inputProps={{ maxLength: 15 }}
                            />
                            <TextField
                                label="Password"
                                type="password"
                                style={{ width: '80%', marginBottom: 10, marginLeft: '10%' }}
                                value={pword}
                                onChange={passwordInputHandler}
                                inputProps={{ maxLength: 150 }}
                            />
                        </div>


                        <div style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex'
                        }}>
                            <Button
                                variant="contained"
                                size="medium"
                                style={{ backgroundColor: Colors.royal, color: Colors.primary, width: '80%', marginTop: 0, marginBottom: 30 }}
                                onClick={loginHandler}
                            >
                                Login
                            </Button>
                        </div>

                        <div style={{ width: '80%', marginBottom: 30, marginLeft: '10%', marginTop: 10 }}>
                            <Link to={'/account/reset-password'} style={{ textDecoration: 'none' }}>
                                <p>Lupa password? <strong style={{ textDecoration: "underline" }}>Click disini</strong> untuk Reset Password.</p>
                            </Link>
                        </div>

                    </div>



                    <div onClick={goToRegister} style={{ marginBottom: 160, cursor: 'pointer' }}>
                        <p style={{ textDecoration: 'none' }}>
                            <p>
                                Belum punya akun? <strong style={{ textDecoration: "underline" }}>Click disini</strong> untuk <strong>Registrasi Gratis</strong> akun baru.
                            </p>
                        </p>
                    </div>

                </div>

            </>
        )
    }

    return (

        <>

            <div className={classes.CourseLoginPage}>

                <div className={classes.CourseLoginPage_Header}>
                    <h1>Daftar Akun Baru</h1>
                </div>

                <div className={classes.CourseLoginPage_Form}>

                    <h2>Form Registrasi</h2>

                    <div className={classes.CourseLoginPage_Header}>
                        <TextField
                            label="Nama"
                            type="text"
                            style={{ width: '80%', marginBottom: 10, marginLeft: '10%' }}
                            value={name}
                            onChange={nameInputHandler}
                            inputProps={{ maxLength: 30 }}
                        />
                        <TextField
                            label="Email"
                            type="email"
                            style={{ width: '80%', marginBottom: 10, marginLeft: '10%' }}
                            value={email}
                            onChange={emailInputHandler}
                            inputProps={{ maxLength: 150 }}
                        />
                        <TextField
                            label="No WhatsApp (contoh: 0812345678)"
                            type="tel"
                            style={{ width: '80%', marginBottom: 10, marginLeft: '10%' }}
                            value={phone}
                            onChange={phoneInputHandler}
                            inputProps={{ maxLength: 15 }}
                        />
                        <TextField
                            label="Password (8-15 karakter)"
                            type="password"
                            style={{ width: '80%', marginBottom: 10, marginLeft: '10%' }}
                            value={pword}
                            onChange={passwordInputHandler}
                            inputProps={{ maxLength: 15 }}
                        />
                        <TextField
                            label="Konfirmasi Password"
                            type="password"
                            style={{ width: '80%', marginBottom: 10, marginLeft: '10%' }}
                            value={confirmPword}
                            onChange={confirmPasswordInputHandler}
                            inputProps={{ maxLength: 15 }}
                        />
                        <FormControl style={{ width: '80%', marginLeft: '10%', marginBottom: 10 }}>
                            <InputLabel>Sudah pernah Investasi Saham ?</InputLabel>
                            <Select
                                value={lv}
                                onChange={lvInputHandler}
                            >
                                <MenuItem value={0}>Belum pernah sama sekali.</MenuItem>
                                <MenuItem value={1}>Baru-baru ini mulai investasi.</MenuItem>
                                <MenuItem value={2}>Sudah lebih dari 2 tahun.</MenuItem>
                                <MenuItem value={9}>Sudah paham dan ahli berinvestasi.</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl style={{ width: '80%', marginLeft: '10%', marginBottom: 10 }}>
                            <InputLabel>Harapan mendaftar kelas ini ?</InputLabel>
                            <Select
                                value={isValue}
                                onChange={isValueInputHandler}
                            >
                                <MenuItem value={true}>Mau belajar "Investasi Saham".</MenuItem>
                                <MenuItem value={false}>Mau belajar "Spekulasi Saham".</MenuItem>
                            </Select>
                        </FormControl>

                        <div style={{ width: '100%' }}>
                            <p style={{ marginLeft: '10%', width: '80%' }}>
                                <strong>Pastikan data diri yang kamu isi sudah benar</strong>, karena jika tidak, kamu tidak akan bisa join Grup Telegram Belajar Saham.
                                <br />
                            </p>
                        </div>

                    </div>

                    <div style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex'
                    }}>
                        <Button
                            variant="contained"
                            size="medium"
                            style={{ backgroundColor: Colors.royal, color: Colors.primary, width: '80%', marginTop: 0, marginBottom: 30 }}
                            onClick={registerHandler}
                        >
                            {isPaying ? `Daftar Sekarang` : `Daftar Gratis`}
                        </Button>
                    </div>

                </div>

                <div onClick={goToLogin}>
                    <p style={{ textDecoration: 'none', cursor: 'pointer' }}>
                        <p>
                            Sudah punya akun? <strong style={{ textDecoration: "underline" }}>Click disini</strong> untuk <strong>Login</strong> dengan akun kamu.
                        </p>
                    </p>
                </div>

            </div>

            <ScrollDownButton />

        </>

    );

}

export default CourseLoginPage;
