import { SET_PARTICIPANTS, SET_RECORDINGS } from "../actions/webinarAction";
import { LOGOUT } from "../actions/authAction";

const initialState = {
    id: '',
    participants: [],
    recordings: []
};


const webinarReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_RECORDINGS: {
            return {
                ...state,
                recordings: action.recordings
            }
        }

        case SET_PARTICIPANTS: {
            return {
                ...state,
                id: action.webinarId,
                participants: action.participants
            }
        }

        case LOGOUT: {
            return {
                ...initialState
            }
        }


        default:
            return state;
    }
}

export default webinarReducer;