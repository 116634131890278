import api from '../../constants/Api';
// import { dasarAnalisaSaham } from '../../constants/Objects';

export const SET_PAYMENT_URL = 'SET_PAYMENT_URL';
export const SET_CLASS_TO_PAY = 'SET_CLASS_TO_PAY';
export const SET_CLASS_DETAILS = 'SET_CLASS_DETAILS';
// export const SET_RECORDINGS = 'SET_RECORDINGS';

export const setClassToPay = (classId) => {
    return { type: SET_CLASS_TO_PAY, classId: classId }
}


// =========================== GET CLASS DETAILS ========================== //
export const setClassDetails = (classId) => {
    return async (dispatch, getState) => {

        // console.log(classId);

        try {
            const response = await fetch(
                api.bnkv + `/class/details/${classId}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json'
                    }
                }
            );
            if (!response.ok) {
                throw new Error('Tidak dapat terkoneksi dengan server.')
            };

            const resData = await response.json();
            // console.log(resData);
            return dispatch({
                type: SET_CLASS_DETAILS,
                details: resData.class
            });

        } catch (err) {
            throw err;
        }
    }
};


// =========================== SET COURSE PAYMENT URL ========================== //
export const setCoursePaymentUrl = () => {
    return async (dispatch, getState) => {

        const token = getState().auth.token;
        const classId = getState().payment.classId;
        const refId = getState().auth.refId;

        try {
            const response = await fetch(
                api.bnkv + `/purchase/create/course`,
                {
                    method: 'PUT',
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        classId: classId,
                        refId: refId
                    })
                }
            );
            if (!response.ok) {
                throw new Error('Tidak dapat terkoneksi dengan server.')
            };

            const resData = await response.json();
            console.log(resData);
            return resData.url

        } catch (err) {
            throw err;
        }
    }
};


// =========================== GET INVOICE DETAILS ========================== //
export const getInvoiceDetails = (invoiceId) => {
    return async (dispatch, getState) => {

        const token = getState().auth.token;

        try {
            const response = await fetch(
                api.bnkv + `/purchase/info/${invoiceId}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            if (!response.ok) {
                throw new Error('Tidak dapat terkoneksi dengan server.')
            };

            const resData = await response.json();
            // console.log(resData);

            return resData

        } catch (err) {
            throw err;
        }
    }
};


// // =========================== WEBINAR REGISTRATION ========================== //
// export const webinarReg = (name, phone, email, ig, lv, value) => {
//     return async (dispatch, getState) => {

//         const webinarId = "21112020";
//         const bodyData = {
//             name: name,
//             phone: phone,
//             email: email,
//             ig: ig,
//             lv: lv,
//             value: value
//         };

//         // console.log(webinarId);
//         // console.log(bodyData);

//         try {
//             const response = await fetch(
//                 api.bnkv + `/webinar/${webinarId}`,
//                 {
//                     method: 'POST',
//                     headers: {
//                         'Content-type': 'application/json'
//                     },
//                     body: JSON.stringify(bodyData)
//                 }
//             );

//             if (!response.ok) {
//                 throw new Error('Tidak dapat terkoneksi dengan server.')
//             };

//             // const resData = await response.json();
//             // console.log(resData);

//         } catch (err) {
//             throw err;
//         }
//     }
// };


// // =========================== WEBINAR PARTICIPANTS LIST ========================== //
// export const getWebinarParticipants = (aId, keyword, webinarId) => {
//     return async (dispatch, getState) => {

//         const bodyData = {
//             aId: aId,
//             keyword: keyword
//         };

//         // console.log(webinarId);
//         // console.log(bodyData);

//         try {
//             const response = await fetch(
//                 api.bnkv + `/admin/webinar-list/${webinarId}`,
//                 {
//                     method: 'POST',
//                     headers: {
//                         'Content-type': 'application/json'
//                     },
//                     body: JSON.stringify(bodyData)
//                 }
//             );

//             if (!response.ok) {
//                 throw new Error('Tidak dapat terkoneksi dengan server.')
//             };

//             const resData = await response.json();
//             // console.log(resData);

//             dispatch({
//                 type: SET_PARTICIPANTS,
//                 webinarId: webinarId,
//                 participants: resData.participants
//             });

//         } catch (err) {
//             throw err;
//         }
//     }
// };


// // =========================== SPECIAL WEBINAR REGISTRATION ========================== //
// export const specialWebinarReg = (name, phone, email, ig, lv, value) => {
//     return async (dispatch, getState) => {

//         const webinarId = "10K";
//         const bodyData = {
//             name: name,
//             phone: phone,
//             email: email,
//             ig: ig,
//             lv: lv,
//             value: value
//         };

//         try {
//             const response = await fetch(
//                 api.bnkv + `/webinar/special/${webinarId}`,
//                 {
//                     method: 'POST',
//                     headers: {
//                         'Content-type': 'application/json'
//                     },
//                     body: JSON.stringify(bodyData)
//                 }
//             );

//             if (!response.ok) {
//                 throw new Error('Tidak dapat terkoneksi dengan server.')
//             };

//             const resData = await response.json();
//             console.log(resData);

//         } catch (err) {
//             throw err;
//         }
//     }
// };
