import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Toolbar from '../navigation/toolbar/Toolbar';
import { Link } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
// import ListAltIcon from '@material-ui/icons/ListAlt';
import MenuBookIcon from '@material-ui/icons/MenuBook';
// import DescriptionIcon from '@material-ui/icons/Description';
// import EmailIcon from '@material-ui/icons/Email';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import SchoolIcon from '@material-ui/icons/School';
// import CastForEducationIcon from '@material-ui/icons/CastForEducation';

import SideDrawer from '../navigation/side-drawer/SideDrawer';
import SubscribeButton from '../UI/buttons/SubscribeButton';

import classes from './Layout.module.css';
import Colors from '../../constants/Colors';

import { setPayOff } from '../../redux/actions/authAction';

const Layout = (props) => {

  const dispatch = useDispatch();

  const [showBackdrop, setShowBackdrop] = useState(false);
  const token = useSelector(state => state.auth.token);

  const goToLogin = () => {
    window.scrollTo(0, 0);
    dispatch(setPayOff());
  }

  return (

    <>
      < Toolbar onShowSideDrawer={() => setShowBackdrop(!showBackdrop)} />
      {showBackdrop ? <SideDrawer show={showBackdrop} close={() => setShowBackdrop(false)} /> : null}

      <main className={classes.Content}>
        {props.children}
      </main>

      <footer className={classes.Layout_Footer}>
        <SubscribeButton />
        <div className={classes.Layout_Footer_Navigator}>
          <Link
            to={token ? '/course/list' : '/'}
            onClick={() => window.scrollTo(0, 0)}
            style={{ color: Colors.turqoise }}
          >
            <HomeIcon style={{ fontSize: 35 }} />
          </Link>
          <Link
            to={'/articles'}
            onClick={() => window.scrollTo(0, 0)}
            style={{ color: Colors.turqoise }}
          >
            <MenuBookIcon style={{ fontSize: 30 }} />
          </Link>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.instagram.com/rbonakapvi/"
            style={{ color: Colors.turqoise }}
          >
            <InstagramIcon style={{ fontSize: 30 }} />
          </a>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.youtube.com/channel/UCOecf7hSPhhJ42DqOIM-Trw"
            style={{ color: Colors.turqoise }}
          >
            <YouTubeIcon style={{ fontSize: 35 }} />
          </a>
          <Link
            to={'/course/list'}
            onClick={goToLogin}
            style={{ color: Colors.turqoise }}
          >
            <SchoolIcon style={{ fontSize: 35 }} />
          </Link>

        </div>
        <div className={classes.Layout_Footer_Copyright}>
          <p>Copyright © {(new Date()).getFullYear()}  - &nbsp;  bonakapvi.com</p>
        </div>
      </footer>
    </>

  )
}

export default Layout;
