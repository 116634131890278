import React from 'react';
import { useDispatch } from 'react-redux';

import Logo from '../../logo/Logo';
import NavigationItems from '../navigation-items/NavigationItems';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';

import { clientLogout } from '../../../redux/actions/authAction';

import Color from '../../../constants/Colors';

import classes from './Toolbar.module.css';

const Toolbar = (props) => {

  const dispatch = useDispatch();

  // =========== LOGING OUT CLIENT ========== //
  const logingOut = () => {
    dispatch(clientLogout());
    console.log('LOGOUT');
  }

  const desktopLogo = [classes.DesktopOnly, classes.Logo].join(' ');
  const mobileLogo = [classes.MobileOnly, classes.Logo].join(' ');

  return (
    <header className={classes.Toolbar}>
      <div className={desktopLogo}>
        <Logo />
      </div>
      <nav className={classes.MobileOnly}>
        <IconButton onClick={props.onShowSideDrawer}
          style={{ backgroundColor: "transparent", alignItems: "center", height: "100%" }}>
          <MenuIcon style={{ color: Color.primary, fontSize: 30 }} />
        </IconButton>
      </nav>
      <nav className={classes.DesktopOnly}>
        <NavigationItems onLogout={logingOut} />
      </nav>
      <div className={mobileLogo}>
        <Logo />
      </div>
    </header >
  );
}

export default Toolbar;
