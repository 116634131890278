import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import Button from '@material-ui/core/Button';
// import TimerIcon from '@material-ui/icons/Timer';
// import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// import LoadingSpinner from '../components/UI/spinner/LoadingSpinner';
// import TelegramButton from '../../components/UI/buttons/TelegramButton';
import LoginModal from '../../components/UI/modal/LoginModal';
import LoadingSpinner from '../../components/UI/spinner/LoadingSpinner';
import ScrollDownButton from '../../components/UI/buttons/ScrollDownButton';

// import Ask from '../../assets/images/ask.png';
// import Profit from '../../assets/images/profit.jpg';
// import Books from '../../assets/images/books.png';


import BasicFee from '../../assets/images/basic-fee.jpg';
// import Fee199 from '../../assets/images/199rb.png';
import Fee249 from '../../assets/images/249rb.png';
import Fee299 from '../../assets/images/299rb.png';
import Fee499 from '../../assets/images/499rb.png';
import Fee599 from '../../assets/images/599rb.png';

import { importantData } from '../../constants/Data';

import Colors from '../../constants/Colors';

import classes from './StockCoursePaymentPage.module.css';
import { setPayOff, setPayOn, clientLogout } from '../../redux/actions/authAction';
import { setClassToPay, setCoursePaymentUrl, setClassDetails } from '../../redux/actions/paymentAction';
import { getCoursesInACat } from '../../redux/actions/courseAction';


const StockCoursePaymentPage = (props) => {

  const dispatch = useDispatch();

  // const topArticles = useSelector(state => state.article.topArticles);

  const token = useSelector(state => state.auth.token);
  const badges = useSelector(state => state.auth.badges);
  const refId = useSelector(state => state.auth.refId);
  const classFee = useSelector(state => state.payment.fee);
  // console.log('DETAIL KELAS', classFee);

  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setisFetching] = useState(true);
  const [mobile, setMobile] = useState(window.matchMedia("(max-width: 499px)").matches);
  const [desktop, setdesktop] = useState(window.matchMedia("(min-width: 900px)").matches);

  const [showPayment, setshowPayment] = useState(false);
  const [payingPerson, setpayingPerson] = useState(importantData.participants[Math.floor(Math.random() * importantData.participants.length)]);
  // console.log(payingPerson);

  const [classPackage, setclassPackage] = useState(1);
  const [showModal, setshowModal] = useState(false);

  const discountCode = props.location.pathname.split('/').pop();

  // const expiryDate = importantData.lastPromoDate;

  const isPremium = classPackage === 1;

  // console.log('REF === ', refId);

  const promoKeyword = [`promo-payday`, `batch-4`];
  const classId = (promoKeyword.includes(refId) || promoKeyword.includes(discountCode))
    ? importantData.promoClass : (refId && refId.length === 24)
      ? importantData.affiliateClass : importantData.normalClass;


  // console.log('CLASS ID === ', classId);
  // console.log('FEE === ', classFee);


  const premiumFeeImage = classFee === 249000 ? Fee249 :
    classFee === 299000 ? Fee299 :
      classFee === 499000 ? Fee499 : Fee599;

  // console.log('TIME === ', timeStamp);

  // ========= RESHUFFLE ======= // 
  const reshufflePerson = useCallback(() => {
    if (showPayment === false) {
      setpayingPerson(importantData.participants[Math.floor(Math.random() * importantData.participants.length)]);
    }
  }, [showPayment]);

  useEffect(() => {
    reshufflePerson();
  }, [showPayment, reshufflePerson])

  // ============================= WINDOW WIDTH CHANGE LISTENER ======================== //
  useEffect(() => {
    const handler = e => setMobile(e.matches);
    window.matchMedia("(max-width: 499px)").addListener(handler);
    return () => {
      window.matchMedia("(max-width: 499px)").removeListener(handler);
    }
  }, [mobile]);

  // ============================= WINDOW WIDTH CHANGE LISTENER ======================== //
  useEffect(() => {
    const handler = e => setdesktop(e.matches);
    window.matchMedia("(min-width: 900px)").addListener(handler);
    return () => {
      window.matchMedia("(min-width: 900px)").removeListener(handler);
    }
  }, [mobile]);

  const settingClassDetails = useCallback(async () => {
    // await dispatch(setClassToPay(classId));
    console.log('GETTING CLASS DETAILS');
    try {
      await dispatch(setClassDetails(classId));
      setisFetching(false);
    } catch (err) {
      console.log('Cannot fetch from server...');
      setisFetching(false);
    }
  }, [dispatch, classId]);

  const testToken = useCallback(async () => {
    // await dispatch(setClassToPay(classId));
    try {
      await dispatch(getCoursesInACat(`stock`));
    } catch (err) {

    }
  }, [dispatch]);

  // ============================= AUTO SET PAY OFF =================================== //
  useEffect(() => {
    settingClassDetails();
    testToken();
    dispatch(setPayOff());
  }, [dispatch, settingClassDetails, testToken]);


  // ============================= PAYMENT HANDLER ======================== //
  const processPayment = async () => {
    if (!token) {
      // IF THERE IS NO TOKEN //
      dispatch(setPayOn());
      setshowModal(true);
    } else {
      dispatch(setPayOff());
      // IF THERE IS TOKEN && ALREADY PAID PREMIUM //
      if (badges.includes('sp101')) {
        console.log('TOKEN >> PREMIUM >> ALREADY PAID');
        props.history.push('/course/list');
      } else {
        // IF THERE IS TOKEN && PAYING PREMIUM //
        // console.log('TOKEN >> PREMIUM >> ALREADY PAID');
        // props.history.push('/course/list');
        // // THE TRUE LOGIC IF REGISTRATION OPEN AGAIN //
        setIsLoading(true);
        console.log('TOKEN >> PREMIUM >> PAYING');
        try {
          await dispatch(setClassToPay(classId));
          const xenditUrl = await dispatch(setCoursePaymentUrl());
          window.location.href = xenditUrl;
        } catch (err) {
          await dispatch(clientLogout());
          return alert('Terjadi kegagalan koneksi dengan server. Mohon ulangi beberapa saat lagi...');
        }
      }
    }
  }

  // ============================= LOGIN or REGISTER HANDLER ======================== //
  const loginHandler = () => {
    if (!isPremium) {
      dispatch(setClassToPay('free'));
    } else {
      dispatch(setClassToPay(classId));
    }
    window.scrollTo(0, 0);
    props.history.push('/course/login');
  }

  const registerHandler = () => {
    if (!isPremium) {
      dispatch(setClassToPay('free'));
    } else {
      dispatch(setClassToPay(classId));
    }
    window.scrollTo(0, 0);
    props.history.push('/course/register');
  }

  // ====================== CLASS PACKAGE INPUT HANDLER ======================= //
  const classPackageInputHandler = (event) => {
    const { value } = event.target;
    setclassPackage(value);
  }

  const PaymentPopUp = () => {
    return (
      <div className={classes.PaymentPopUp}>
        <p><b>{payingPerson}</b> baru saja <b>join Kelas Premium</b></p>
      </div>
    )
  }

  const showHidePopUp = () => {
    setshowPayment(true);
    setTimeout(() => setshowPayment(false), 21000);
  }


  setTimeout(() => showHidePopUp(), 6000);
  // setTimeout(() => changePaymentPopUp(), 3000);



  if (isLoading) {
    return (
      <div className={classes.Centered}>
        <LoadingSpinner />
      </div>
    )
  }



  return (

    <>

      <div className={classes.StockCoursePaymentPage}>

        {/* <TelegramButton
          url={`https://t.me/joinchat/PdVQRhlqhw8xhNWGqAro8A`}
        /> */}

        <LoginModal
          show={showModal}
          close={() => setshowModal(false)}
          loginHandler={loginHandler}
          registerHandler={registerHandler}
        />

        {showPayment ? <PaymentPopUp /> : null}

        <ScrollDownButton />

        <div className={classes.StockCoursePaymentPage_Header} style={{ marginBottom: 0, paddingBottom: 30 }}>
          <h1 className={classes.StockCoursePaymentPage_Header_Hello} style={{ color: Colors.turqoise, marginBottom: 10 }}>PILIH PAKET</h1>
          <div>
            <FormControl style={{ width: '80%', marginLeft: '10%', marginBottom: 0, backgroundColor: 'white' }}>
              <InputLabel>Pilih Paket Kelas Investasi Saham</InputLabel>
              <Select
                value={classPackage}
                onChange={classPackageInputHandler}
              >
                {token ? null : <MenuItem value={0}>Kelas Basic (Free)</MenuItem>}
                {<MenuItem value={1}>Kelas Premium</MenuItem>}
              </Select>
            </FormControl>
          </div>
        </div>

        <div className={classes.StockCoursePaymentPage_Intro} style={{ paddingTop: 0, paddingBottom: 20, marginTop: 0 }}>
          <h2 style={{ marginTop: 0, marginBottom: 10 }}>Konten Yang Kamu Dapatkan</h2>
          <div>
            {!isPremium ?
              <ul>
                <li>Dasar Investasi Saham</li>
                <li>Strategi & Metode Analisa Saham</li>
                <li>Analisa Paper / Laporan Keuangan <strong>(Basic Version)</strong></li>
                <li>Analisa Rock / Kualitas Bisnis <strong>(Basic Version)</strong></li>
                <li>Analisa Scissors / Valuasi Harga <strong>(Basic Version)</strong></li>
                <li>Telegram Group</li>
              </ul>
              :
              <ul>
                <li>Dasar Investasi Saham</li>
                <li>Strategi & Metode Analisa Saham</li>
                <li>Analisa Paper / Laporan Keuangan <strong>(Full Version)</strong></li>
                <li>Analisa Rock / Kualitas Bisnis <strong>(Full Version)</strong></li>
                <li>Analisa Scissors / Valuasi Harga <strong>(Full Version)</strong></li>
                <li>Manajemen Portofolio <strong>(Premium)</strong></li>
                <li>Money Management <strong>(Premium)</strong></li>
                <li>Market Outlook Webinar <strong>(Free For 1 Year)</strong></li>
                <li>Premium WhatsApp Group <strong>(Fast Response)</strong></li>
                <li>Bimbingan Belajar Saham Intensif <strong>(For 1 Year)</strong></li>
              </ul>}
          </div>
        </div>

        {isPremium ?
          <p style={{ color: 'black', width: '80vw', marginLeft: '10vw', marginRight: '10vw', marginTop: 0, marginBottom: 20, fontSize: 18 }}>Cukup <strong>bayar sekali</strong>, semua konten bisa kamu <strong>akses selama satu tahun.</strong></p> : null}

        <div style={{ width: '80vw', marginLeft: '10vw', marginRight: '10vw', marginTop: 10, marginBottom: 20 }}>
          {isFetching ? <LoadingSpinner /> :
            <img style={{ width: `100%`, maxWidth: 500 }} src={classPackage === 0 ? BasicFee : premiumFeeImage} alt={`premium`}>
            </img>}
        </div>

        {isPremium ?
          <p style={{ color: 'black', width: '80vw', marginLeft: '10vw', marginRight: '10vw', fontSize: 18 }}><strong>Diskon Pendaftaran Batch 5</strong>, berlaku untuk <strong>30 pendaftar pertama</strong> </p> : null}

        <div>
          <div style={{
            justifyContent: 'center',
            alignItems: 'flex-start',
            display: 'flex',
            marginLeft: '10vw',
            width: '90vw',
            marginBottom: 30,
            flexDirection: 'column'
          }}>
            <Button
              variant="contained"
              size="medium"
              style={{ backgroundColor: Colors.royal, color: Colors.primary, width: '80vw', marginTop: isPremium ? 0 : 30, marginBottom: 0, maxWidth: 500, fontSize: 18 }}
              onClick={processPayment}
            >
              {isPremium ? `BAYAR SEKARANG` : `LANJUT`}
            </Button>
          </div>
        </div>

        <div className={classes.StockCoursePaymentPage_Intro} style={{ paddingTop: 30, paddingBottom: 20, borderTopWidth: 3, borderColor: 'gray', borderStyle: 'dashed', borderBottomWidth: 0, borderRightWidth: 0, borderLeftWidth: 0 }}>
          <h2 style={{ marginTop: 0, marginBottom: 10 }}>Frequently Asked Questions (FAQ)</h2>

          <h4>Apa perbedaan dari Basic Version dan Full Version?</h4>
          <p style={{ marginTop: 0 }}>Untuk Basic Version, ada beberapa konten yang tidak bisa diakses. Sedangkan, untuk Full Version, kamu bisa mengakses semua konten yang tersedia :)</p>

          <h4>Kalau daftar Basic sekarang, apa bisa upgrade ke Premium di kemudian hari?</h4>
          <p style={{ marginTop: 0 }}>Tentu saja bisa :) Kamu bisa pelajari dahulu konten yang Basic. Jika dirasa konten Basic tersebut masih kurang, kamu bisa Upgrade ke Premium di kemudian hari.</p>

          <h4>Berarti kalau daftar yang Basic tidak bisa akses pelajaran Manajemen Portofolio & Money Management ya?</h4>
          <p style={{ marginTop: 0 }}>Betul :) Pelajaran Manajemen Portofolio dan Money Management disiapkan khusus untuk Member Premium</p>

          <h4>Apakah konten pelajaran bisa diakses selama satu tahun penuh setelah membayar?</h4>
          <p style={{ marginTop: 0 }}>Betul sekali :) Dengan membayar Kelas Premium, kamu bisa mengakses semua konten yang ada (Basic & Full Version) selama satu tahun (365 hari) kedepan.</p>

          <h4>Apa yang terjadi setelah masa satu tahun membership Kelas Premium tersebut habis?</h4>
          <p style={{ marginTop: 0 }}>Setelah habis satu tahun (365 hari), kamu bisa memperpanjang masa membership Kelas Premium dengan membayar sebesar <strong>Rp 199.000</strong> saja untuk satu tahun berikutnya.</p>

          <h4>Bagaimana kalau saya tidak memperpanjang membership setelah masa satu tahun itu habis?</h4>
          <p style={{ marginTop: 0 }}>Jika tidak memperpanjang membership, kamu jadi tidak bisa mengakses konten Full Version & Market Outlook Webinar di tahun berikutnya. Tapi tenang, kamu masih dapat berkomunikasi di Premium WhatsApp Group dan tetap bisa mengikuti beberapa event yang diadakan khusus untuk alumni Kelas Premium :)</p>

          <h4>Apa isi dari Market Outlook Webinar?</h4>
          <p style={{ marginTop: 0 }}>Market Outlook Webinar adalah webinar yang diadakan setiap tiga bulan sekali (tiap kuartal) khusus untuk Member Premium. Di webinar ini kita akan membahas mengenai potensi dampak kondisi makro-ekonomi global terhadap portofolio investasi kita.</p>

          <h4>Apakah saya masih bisa mengikuti Market Outlook Webinar jika tidak memperpanjang masa membership?</h4>
          <p style={{ marginTop: 0 }}>Mohon maaf sekali, Market Outlook Webinar hanya bisa diakses oleh anggota yang status membershipnya masih aktif / diperpanjang.</p>

          {/* <h4>Apa yang dimaksud Live Webinar Setiap Bulan?</h4>
          <p style={{ marginTop: 0 }}>Untuk Member Premium, setiap bulannya akan diundang ke Webinar Special Premium. Di webinar tersebut peserta dapat belajar dan menanyakan pertanyaan secara langsung (Live) kepada kami :)</p> */}

          <h4>Apakah saya akan mendapatkan rekomendasi saham?</h4>
          <p style={{ marginTop: 0 }}>Sayang sekali, di kelas ini <strong>kami tidak memberikan rekomendasi saham </strong> apapun.</p>


          <h4>Apa kelebihan dari Grup WhatsApp Premium?</h4>
          <p style={{ marginTop: 0 }}>Karena jumlah anggota Grup WhatsApp Premium yang terbatas, maka response yang diterima akan jauh lebih cepat dari Grup Telegram Basic. Selain itu, secara berkala akan ada info yang hanya dibagikan di Grup WhatsApp Premium.</p>

          <h4>Apakah maksud dari Bimbingan Belajar Saham Intensif?</h4>
          <p style={{ marginTop: 0 }}>Setiap member Kelas Premium akan dibagi ke dalam beberapa Batch (Angkatan Belajar) untuk mengikuti Bimbingan Belajar Saham Intensif selama satu tahun bersama mentor berpengalaman. Selain diajarkan mengenai teori dan prinsip investasi yang teruji, setiap Member Premium juga akan mendapatkan tips praktis dalam menerapkan Strategi Intelligent Investing di pasar saham.</p>

          <h4>Apakah Diskon Kelas Premium akan diadakan lagi di kemudian hari?</h4>
          <p style={{ marginTop: 0 }}>Untuk Diskon, kami tidak bisa memastikan akan diselenggarakan lagi di kemudian hari atau tidak. Oleh karenanya, jika sekarang sedang ada Diskon, kamu bisa manfaatkan dengan sebaik-baiknya :)</p>

          <h4>Apakah ada benefit lain untuk member Kelas Premium?</h4>
          <p style={{ marginTop: 0 }}>Tentu saja! Setiap member Kelas Premium akan mendapatkan diskon (potongan harga) khusus untuk produk-produk kami lainnya di kemudian hari :)</p>

          {/* <h4>Kenapa ada Transaction Fee di perhitungan pembayaran?</h4>
          <p style={{ marginTop: 0 }}>Karena kami menggunakan third party untuk sistem pembayaran, setiap transaksi dikenakan fee sebesar Rp 6.000 oleh third party tersebut.</p> */}

          <h4>Siapa yang memberikan materi pelajaran di course ini?</h4>
          <p style={{ marginTop: 0 }}>Semua materi pelajaran diberikan langsung oleh Raymond Bonakapvi, founder dari website ini. Selain pengalamannya yang panjang di pasar saham (sejak tahun 2015), beliau juga memiliki sertifikat resmi WMI (Wakil Manajer Investasi) dan WPPE (Wakil Perantara Perdagangan Efek) yang diterbitkan oleh TICMI.</p>

        </div>


      </div>





    </>

  );
}

export default StockCoursePaymentPage;
